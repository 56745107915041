<!-- /**
 * @description: PDF模板组件
 * 功能：
 * 1、采购单，报价单，发票，订单对象的详情页新增PDF视图
 * 开发人员：张凤
 * 开始时间：2022-7-11
 * 结束时间：2022-7-31
 */ -->

<template>
  <div class="pdf-content">
    <!-- 订单编号 -->
    <div class="pdf-header">
      <div class="header-title">{{ objectApiName }} </div>
      <div class="header-number">{{ objectApiNumber }}</div>
    </div>
    <!-- 详细信息 -->
    <div class="pdf-information-box">
      <div
        v-for="(item, index) in templateData"
        :key="item.id"
        class="infor-item"
      >
        <!-- 信息标题 -->
        <div class="pdf-infor-header" v-show="item.hidetitle == 0">
          {{ item.name }}
        </div>
        <!-- 字段 -->
        <div
          class="pdf-infors"
          :class="index == templateData.length - 1 ? 'pdf-right' : ''"
          v-if="item.type != 'list'"
        >
          <div
            class="pdf-infor-item"
            v-for="(filedItem, filedindex) in item.fields"
            :class="
              filedindex == Number(item.fields.length) - 1 &&
              index == templateData.length - 1
                ? 'pdf-total'
                : ''
            "
            :key="filedItem.id"
          >
            <div
              class="pdf-item-label"
              :class="index < templateData.length - 1 ? 'label-color' : ''"
              v-if="filedItem.name"
            >
              {{ filedItem.name }}:
            </div>
            <div
              v-if="
                filedItem.name &&
                filedItem.schemetable_id != 'account' &&
                filedItem.schemefieldName != 'account_name' &&
                filedItem.schemefield_type != 'IMG'
              "
              class="pdf-item-value"
              v-html="filedItem.fieldValue"
            ></div>
            <!-- 客户名称加跳转 -->
            <div
              v-if="
                (filedItem.schemetable_id == 'account' ||
                  filedItem.schemefieldName == 'account_name') &&
                filedItem.name &&
                filedItem.schemefield_type != 'IMG'
              "
            >
              <a
                :href="`/#/commonObjects/detail/${filedItem.khmc}/DETAIL`"
                style="text-decoration: none; color: #006dcc; cursor: pointer"
                >{{ filedItem.fieldValue }}</a
              >
            </div>
            <div v-if="filedItem.schemefield_type == 'IMG'" class="file-img">
              <img :src="getHeadImg(filedItem.fileid)" />
            </div>
          </div>
        </div>
        <!-- 相关列表显示表格数据 -->
        <div v-if="item.type == 'list'" class="pdf-table">
          <el-table
            :data="item.templateTable"
            border
            style="width: 100%; margin-top: 20px"
          >
            <el-table-column
              :prop="headeritem.prop"
              :label="headeritem.label"
              v-for="headeritem in item.templateHeader"
              :key="headeritem.label"
              :width="getComputedWidth(item, headeritem)"
            >
            </el-table-column>
          </el-table>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import VueCookies from "vue-cookies";

export default {
  props: {
    // 对象名称
    objectApiName: {
      type: String,
      default: "",
    },
    // 记录编号
    objectApiNumber: {
      type: String,
      default: "",
    },
    //模板数据
    templateData: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {};
  },
  created() {},
  methods: {
    //获取图片路径
    getHeadImg(id) {
      let tokenCookie = VueCookies.get("JSESSIONID");
      //域名
      let imgUrlCookie = VueCookies.get("domainName");
      return `${imgUrlCookie}/chatterfile.action?m=preview&fileid=${id}&binding=${tokenCookie}&dandan=${Math.random()}`;
    },
    // 动态计算表格宽度
    // 产品和描述的宽度为普通列宽的2倍
    getComputedWidth(data, item) {
      let totol = Number(data.templateHeader.length);
      let widthNum = parseInt(647 / (totol + 1));
      if (item.prop == "chanpin" || item.prop == "product2id"|| item.prop == "plan") {
        return 647 - widthNum * (totol - 1);
      } else {
        return widthNum;
      }
    },
  },
  mounted() {},
};
</script>
<style lang="scss" scoped>
::v-deep .el-table .cell {
  word-break: break-all !important;
  white-space: pre-line; /*保留换行符*/
  padding-right: 0 !important;
}
.pdf-content {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  margin-bottom: 20px;
  min-height: 400px;
  .pdf-header {
    margin-top: 100px;
    width: 252px;
    display: flex;
    flex-direction: column;
    margin-left: calc(100% - 252px);
    .header-title {
      color: #006DCC;
      font-size: 30px;
      font-weight: 400;
    }
    .header-number {
      color: #080707;
      font-size: 18px;
      font-weight: bold;
    }
  }
  .pdf-information-box {
    padding: 52px 66px;
    height: 100%;
    .pdf-infor-header {
      height: 36px;
      line-height: 36px;
      width: 100%;
      background: #eeeeee;
      font-size: 14px;
      font-weight: bold;
      padding-left: 8px;
      margin-top: 20px;
    }
    .pdf-infors {
      display: flex;
      flex-wrap: wrap;
      .pdf-infor-item {
        width: 45%;
        display: flex;
        margin-top: 20px;
        .pdf-item-label {
          width: 100px;
          font-size: 12px;
        }
        .label-color {
          color: #666666;
        }
        .pdf-item-value {
          width: 55%;
          font-size: 12px;
        }
        .file-img {
          text-align: left;
          max-width: 160px;
          max-height: 112px;
          img {
            width: 100% !important;
            height: 100% !important;
          }
        }
      }
      .pdf-infor-item:nth-child(2n) {
        margin-left: 10%;
      }
    }
    .pdf-total{
      max-height: 46px;
      background: #f5f5f5;
      padding: 5px;
    }
    .pdf-right,.pdf-total {
      .pdf-item-label {
        width: 50%;
        text-align: right;
      }
      .pdf-item-value {
        width: 50%;
        text-align: right;
      }
      .pdf-infor-item:nth-child(2n) {
        margin-left: 55%;
      }
      .pdf-infor-item {
        width: 50%;
      }
    }
    .infor-item:last-child {
      .pdf-infor-item {
        margin-top: 10px;
      }
    }
  }
}

::v-deep .el-table th.el-table__cell {
  color: #666666 !important;
  font-size: 12px !important;
  font-family: Regular !important;
  background: #fcfcfc !important;
  height: 40px !important;
}
::v-deep .el-table .el-table__cell {
  padding: 8px 0 !important;
  font-size: 12px !important;
}
</style>