<!-- /**
 * @description: PDF视图详情页
 * 功能：
 * 1、采购单，报价单，发票，订单对象的详情页新增PDF视图
 * 开发人员：张凤
 * 开始时间：2022-7-11
 * 结束时间：2022-7-31
 */ -->
 <template>
    <!-- 开发人员：李长兵 -->
    <div
      class="detai-box"
      v-loading.fullscreen.lock="fullscreenLoading || noClick"
    >
      <div class="home" v-if="!isQuery && !fullscreenLoading">
        <div class="noPermissionBox">
          <svg class="icon" aria-hidden="true">
            <use href="#icon-file404"></use>
          </svg>
          <p class="noPermissionTip">
            <!-- 抱歉，您无权限查看该条数据，请联系系统管理员。 -->
            {{ $t("vue_label_notice_nopermission_view") }}
          </p>
          <p style="text-align: center">
            <el-button type="primary" size="mini" @click="goBack">
              <!-- 返回上一级 -->
              {{ $t("label.goback") }}
            </el-button>
          </p>
        </div>
      </div>
      <div v-show="isQuery" id="contentMainBox" ref="home">
        <div class="contentBoxs">
          <!-- 顶部按钮 -->
          <div class="box-content" v-if="isShowType">
            <div class="back-box">
              <i class="iconfont iconjiantou-zuo left" @click="goBack"></i>
              <div class="title" v-html="fieldValue"></div>
            </div>
            <div class="header_right">
              <div class="see-view-btn" @click="SeeOldView">
                <!-- 查看PDF视图  -->
                {{ $t("c1022") }}
                <el-switch v-model="seeViewType"> </el-switch>
              </div>
              <div class="four-btn">
                <!-- 编辑 -->
                <el-tooltip
                  class="item"
                  effect="dark"
                  :content="$t('label.department.user.edit')"
                  placement="top-start"
                >
                  <div
                    class="edit-btn left-radius"
                    @click="recordOperationBtn(showEditButton, 'noclick')"
                  >
                    <svg class="icon" aria-hidden="true">
                      <use href="#icon-edit_def"></use>
                    </svg>
                  </div>
                </el-tooltip>
                <!-- PDF：点击后，展示生成文件弹窗 -->
                <el-tooltip
                  class="item"
                  effect="dark"
                  content="PDF"
                  placement="top-start"
                >
                  <div
                    class="edit-btn"
                    @click="getDownPdfFile"
                  >
                    <svg class="icon" aria-hidden="true">
                      <use href="#icon-linear-pdf"></use>
                    </svg>
                  </div>
                </el-tooltip>
                <!-- 打印 -->
                <el-tooltip
                  class="item"
                  effect="dark"
                  :content="$t('label.tabpage.printz')"
                  placement="top-start"
                >
                  <div @click="printDetail" class="edit-btn">
                    <svg class="icon" aria-hidden="true">
                      <use href="#icon-icon-print"></use>
                    </svg>
                  </div>
                </el-tooltip>
                <!-- 邮件 -->
                <el-tooltip
                  class="item"
                  effect="dark"
                  :content="$t('label.chatter.mail')"
                  placement="top-start"
                >
                  <div
                    class="edit-btn right-radius"
                    @click="recordOperationBtn(showMailButton)"
                  >
                    <svg class="icon" aria-hidden="true">
                      <use href="#icon-mail"></use>
                    </svg>
                  </div>
                </el-tooltip>
                <!-- 锁定，解决锁定 -->
                <el-tooltip
                  class="item"
                  effect="dark"
                  :content="lockingButton.label"
                  placement="top-start"
                >
                  <el-button
                    class="button"
                    style="margin-left: 10px; padding: 9px 12px"
                    v-if="lockingButton && lockingButton.label"
                    size="mini"
                    @click="recordOperationBtn(lockingButton)"
                    >{{ lockingButton.label }}</el-button
                  >
                </el-tooltip>
                <!--审批-->
                <el-button-group>
                  <el-button
                    class="button"
                    style="margin-left: 10px; padding: 9px 12px"
                    size="mini"
                    :loading="submitStatus"
                    v-for="(item, index) in showHistoryBtn"
                    :key="index"
                    @click="historyDeal(item)"
                    >{{ item.label }}</el-button
                  >
                  <el-popover
                    placement="bottom-end"
                    popper-class="pop_box"
                    trigger="hover"
                    v-if="hideHistoryBtn.length > 0"
                  >
                    <div class="approval" slot="reference">
                      <svg class="icon" aria-hidden="true">
                        <use href="#icon-blue_sj"></use>
                      </svg>
                    </div>
                    <ul class="moreButton" style="width: 100px">
                      <li
                        v-for="(item, index) in hideHistoryBtn"
                        :key="index"
                        @click="historyDeal(item)"
                        style="cursor: pointer"
                      >
                        {{ item.label }}
                      </li>
                    </ul>
                  </el-popover>
                </el-button-group>
                <el-popover
                  placement="bottom-end"
                  popper-class="pop_box"
                  trigger="click"
                  :visible-arrow="false"
                  v-if="moreButton.length > 0"
                >
                  <div class="xiala" slot="reference">
                    {{ $t("label.more") }}
                  </div>
                  <ul class="moreButton" :class="{ noClick: noClick }">
                    <!-- 编辑阶梯折扣(只有在折扣计划对象下才会有) -->
                    <li
                      class="li"
                      v-if="editDiscountTiers"
                      style="cursor: pointer"
                      @click="editDiscount"
                    >
                      <!-- 编辑阶梯折扣 -->
                      {{ editDiscountTiers }}
                    </li>
                    <!-- 编辑阶梯折扣(只有在折扣计划对象下才会有) -->
                    <li
                      class="li"
                      style="cursor: pointer"
                      @click="editProduct"
                      v-if="editQuoteline"
                    >
                      <!-- 编辑报价行 -->
                      {{ editQuoteline }}
                    </li>
                    <!-- 付款按钮(只有在伙伴账户订单对象 且订单未支付时显示) -->
                    <li
                      class="li"
                      style="cursor: pointer"
                      @click="goToPay"
                      v-if="showPayButton"
                    >
                      <!-- 付款 -->
                      {{ $t("label.quickbooks.payments") }}
                    </li>

                    <!-- 群策 勿删 -->
                    <li
                      class="li"
                      style="cursor: pointer"
                      @click="judgeIsHas"
                      v-if="
                        isShowChat &&
                        (this.objId === 'account' ||
                          this.objId == 'opportunity') &&
                        //是否为伙伴云用户
                        !this.Partnercloud
                      "
                    >
                      <svg
                        class="icon qcImg"
                        aria-hidden="true"
                        style="
                          font-size: 14px;
                          margin-top: -3px;
                          margin-right: 3px;
                        "
                      >
                        <use :href="qcImg"></use>
                      </svg>
                      <span>
                        {{ $t("label_mobile_buttom_team") }}
                      </span>
                    </li>
                    <!-- 任务看板，资源使用视图 -->
                    <li
                      class="li"
                      v-if="isShowGanttButton"
                      style="cursor: pointer"
                      @click="toGannt('kanbanTaskPage')"
                    >
                      <!-- 任务看板 -->
                      {{ $t("label.projectManagement.kanban") }}
                    </li>
                    <li
                      class="li"
                      v-if="isShowGanttButton"
                      style="cursor: pointer"
                      @click="toGannt('isGantt2')"
                    >
                      <!-- 资源使用视图 -->
                      {{ $t("label.projectManagement.Resource.usage.view") }}
                    </li>
                    <!-- 下拉列表中的按钮 -->
                    <li
                      class="li"
                      v-for="(item, index) in moreButton.filter((items)=>{
                        return items!='manageExternalUsers' && items.name !='Edit'
                      })"
                      :key="index"
                      @click="recordOperationBtn(item, 'noclick')"
                      style="cursor: pointer"
                    >
                      <span>{{ item.label }}
                      </span>
                    </li>
                  </ul>
                </el-popover>
              </div>
            </div>
          </div>
        </div>
        <div class="pdfAndrelation">
          <div class="relation-list-box">
            <!-- 右侧tab模块 -->
            <div class="tab-content">
              <div class="tab-header">
                <el-tabs
                  v-model="activeName"
                  style="width: 100%"
                  @tab-click="handleClick"
                >
                  <el-tab-pane
                    v-for="item in tabData"
                    :key="item.relatedlistId"
                    :label="item.name"
                    :name="item.relatedlistId"
                  >
                  </el-tab-pane>
                </el-tabs>
              </div>
              <div class="publicAndRelation">
                <!-- 动态 -->
                <div v-show="activeName == 'second'" class="limit-height">
                  <!-- fieldValue个人详情页到某人 -->
                  <dynamicPublic
                    v-show="isShowdynamicPublics == 'true'"
                    ref="dynamicPublics"
                    :isDetailType="'pdfView'"
                    :recordName="bigTitle"
                    :postShowType="postShowType"
                    :tabheight="'100%'"
                    :activeName="activeName"
                    :fieldValue="userName"
                    :isShowType="isShowType"
                    :dataId="id"
                  ></dynamicPublic>
                  <div
                    class="isEnableBox"
                    v-show="isShowdynamicPublics == 'false'"
                  >
                    <svg
                      class="icon"
                      style="width: 200px; margin-bottom: 20px; height: 110px"
                      aria-hidden="true"
                    >
                      <use :href="'#icon-PostsNoEnable'"></use>
                    </svg>
                    <div>
                      {{ $t("setup.chatter.enabled") }}
                    </div>
                  </div>
                </div>
                <!-- 相关列表组件 -->
                <div  class="relation-content" v-show="activeName != 'second'">
                      <!-- 相关列表菜单及详细信息 -->
                      <list
                        ref="listChild"
                        id="listChild"
                        :class="detailLayoutMode === 'mode1' ? 'left' : 'right'"
                        :detailGroupId="detailGroupId"
                        :isShowType="isShowType"
                        :viewDetail="'pdf'"
                        :currentRelatedlistIds="relatedlistIds"
                        :tabName="tabName"
                        :dataId="id"
                        :fieldValue="fieldValue"
                        :bigTitle="bigTitle[0] ? bigTitle[0].fieldValue : ''"
                        :isTag="isTag"
                        :detailLayoutMode="detailLayoutMode"
                        :objectApi="objectApi"
                        :prefix="prefix"
                        :isEditDetail="isEdit"
                        :isQueryDetail="isQuery"
                        :locked="isLocked"
                        :canEditOwner="isCanEditOwner"
                        @refreshAll="refreshAll"
                        @refreshUpload="refreshUpload"
                        @topHistoryBtn="getHistoryBtn"
                        @topHistorySubmit="getHistorySubmit"
                        @setScroll="getScroll"
                        @resetObjectInfo="resetObjectInfo"
                        @convert="convert"
                        @getPricebook="getPricebook"
                        @dataListes="dataListes"
                        @personsNameMethod="personsNameMethod"
                        :typedisabled="typedisabled"
                        @getstate="getstate"
                        ></list>
                  <!-- 添加文件-->
                  <add-files
                    ref="addFile"
                    :isFromFileCenter="false"
                    :dialogAddVisible="addFileDialogVisible"
                    @addSubmit="addFileSubmit"
                    @close="addFileClose"
                  ></add-files>

                  <!-- 相关列表添加文件 -->
                  <add-files
                    ref="atoLib"
                    :isFromFileCenter="false"
                    :dialogAddVisible="dialogAddVisible"
                    @addSubmit="addWorkSubmit"
                    @close="addWorkClose"
                  ></add-files>
                </div>
                <!-- PDF视图模板区域  -->
                <div
                  class="pdf-view-box"
                  @mouseover="mouseTemplate"
                  @mouseleave="leaveTemplate"
                  v-loading="pdfTemplateLoad"
                >
                  <div
                    class="triangle"
                    :class="
                      templateStatus != $t('label.knowledgebase.draft')
                        ? 'triangle-blue'
                        : 'triangle-grey'
                    "
                  ></div>
                  <div
                    :class="
                      templateStatus != $t('label.knowledgebase.draft')
                        ? 'streamer-blue'
                        : 'streamer-grey'
                    "
                  >
                    <span>{{ templateStatus }} </span>
                  </div>
                  <div
                    class="triangle1"
                    :class="
                      templateStatus != $t('label.knowledgebase.draft')
                        ? 'triangle1-blue'
                        : 'triangle1-grey'
                    "
                  ></div>
                  <div class="changePage" v-show="templateEditBtn">
                    <el-popover
                      placement="bottom"
                      trigger="click"
                      :visible-arrow="false"
                      ref="buttonListPopper"
                      popper-class="button-list-popper"
                    >
                      <div slot="reference">{{ $t("c1052") }}</div>
                      <div
                        v-show="templateEditBtn"
                        class="buttonListBox"
                        @mouseover="mouseTemplate"
                        @mouseleave="leaveTemplate"
                      >
                        <ul class="sortList" v-if="templateBtn.length > 0">
                          <li class="button-item item" style="cursor: default">
                            {{ templatename }}
                          </li>
                          <li
                            v-for="item in templateBtn"
                            :key="item.id"
                            class="item button-item"
                            @click="chooseTemplateBtn(item.id)"
                          >
                            {{ item.name }}
                          </li>
                        </ul>
                      </div>
                    </el-popover>
                  </div>
                  <!-- PDF模板样式 -->
                  <pdf-template
                    ref="pdfTemplateRef"
                    :objectApiName="objectApiName"
                    :objectApiNumber="objectApiNumber"
                    :templateData="templateData"
                  >
                  </pdf-template>
                </div>
              </div>
            </div>
            <!-- 邮件互动页面特殊处理 -->
            <div
              :style="this.objId === 'cloudccMailActivity' ? 'display: none' : ''"
              id="activityAndDynamicPublics"
              :class="detailLayoutMode === 'mode1' ? 'right' : 'left'"
            >
              <el-card
                v-show="objectApi === 'CloudccKArticle'"
                class="box-card_left"
                :style="{ height: height, 'margin-bottom': '10px' }"
              >
                <!--知识库展示查看翻译-->
                <div style="min-height: 100px">
                  <!-- 查看翻译 -->
                  <h3 style="margin-left: 10px; margin-top: 20px">
                    {{ $t("label.knowledgebase.view.translation") }}
                  </h3>
                  <!-- 请选择 -->
                  <el-select
                    v-model="langevalue"
                    v-if="langeoptionslength > 0"
                    :placeholder="$t('label.emailtocloudcc.select1')"
                    style="width: 90%; margin-left: 10px"
                    @change="langechange(langevalue)"
                  >
                    <el-option-group
                      v-for="group in langeoptions"
                      :key="group.label"
                      :label="group.label"
                    >
                      <el-option
                        v-for="item in group.options"
                        :key="item.value"
                        :label="item.label"
                        :value="item.value"
                      >
                      </el-option>
                    </el-option-group>
                  </el-select>
                  <!-- 没有已发布或草稿翻译版本 -->
                  <el-select
                    v-else
                    v-model="blankValue"
                    disabled
                    :placeholder="
                      $t('label.knowledgebase.nopublished.draft.translations')
                    "
                    style="width: 90%; margin-left: 10px"
                  >
                  </el-select>
                </div>
              </el-card>
              <el-card
                v-if="
                  isOpenService === 'true' &&
                  (objectApi === 'WorkOrder' || objectApi === 'cloudcccase')
                "
                class="box-card_left"
                :style="{ height: height, 'margin-bottom': '10px' }"
              >
                <!--            工作订单下展示重大事件-->
                <div style="min-height: 100px">
                  <important-event
                    ref="importantEvent"
                    :workOrderId="id"
                  ></important-event>
                </div>
              </el-card>
            </div>
          </div>
        </div>
      </div>
      <!-- 共享弹窗 -->
      <Share ref="isShare" :shares="shares"></Share>
      <!-- pdf预览 -->
      <pdf-viewer
        :is-show="pdfViewerAttr.isShow"
        :top="3"
        :title="pdfViewerAttr.title"
        :pdfUrl="pdfViewerAttr.pdfUrl"
        :fullScreen="false"
        @cancel="closeViewerDialog"
        @saveToAppendix="saveToAppendix"
      >
      </pdf-viewer>
      <!-- 提交待审批弹窗 -->
      <nextApproval ref="nextApprovalref"></nextApproval>

      <!-- 共享弹窗 -->
      <synchro ref="synchro"></synchro>
      <!-- 详情页写死 type = active
      修改： 王钰祥 -->
      <!-- 详情页复制/编辑记录  -->
      <create-edit-obj
        ref="createEditObj"
        :prefix="prefix"
        :id="id"
        :object-api="objectApi"
        :object-name="tabName"
        :dataListPartner="dataList"
        :relatedlistIds="relatedlistIds"
        @Refreshes="Refreshes"
        @save="save"
        type="active"
      ></create-edit-obj>
      <!-- 企业微信联系人转联系人或潜在客户专用 -->
      <create-edit-obj
        ref="ewcreateEditObj"
        :prefix="ewprefix"
        :objectApi="ewobjectApi"
        :object-name="ewtabName"
        @Refreshes="Refreshes"
        @save="save"
        type="active"
        :ewId="ewId"
        :ewData="ewData"
      ></create-edit-obj>
      <!-- 客服云-访客/会话/语音专用 -->
      <create-edit-obj
        ref="ceateEditObjForCustomer"
        :prefix="prefixCustomer"
        :objectApi="objectApiCustomer"
        :object-name="objectNameCustomer"
        @Refreshes="Refreshes"
        @save="save"
        type="active"
      ></create-edit-obj>
      <!-- 发票详情页新建回款按钮对应的特殊新建页面 -->
      <create-edit-obj
        ref="invoiceCeateEditObj"
        :object-name="objectNameReceive"
        :prefix="invoicePrefix"
        :objectApi="invoiceApi"
        :isReceivable="true"
        :customId="customId"
        :invoiceInfo="invoiceInfo"
        @save="save"
      ></create-edit-obj>
      <!-- 企业微信联系人转联系人或潜在客户专用 -->
      <el-dialog
        :title="
          objId === 'account'
            ? $t('vue_label_commonobjects_detail_group_strategy')
            : $t('vue_label_commonobjects_detail_group_opportunitystrategy')
        "
        :visible.sync="isShowTag"
        width="40%"
        :append-to-body="true"
        custom-class="qunce-dialog"
      >
        <div>
          <!-- 加入后，该群策将显示在您的群策列表，群策动态会实时推送给您。 -->
          {{ $t("vue_label_commonobjects_detail_real_time") }}
        </div>
        <el-button @click="cancelDia" class="cancel">
          <!-- 取消 -->
          {{ $t("button_partner_cancel") }}
        </el-button>
        <el-button type="primary" @click="joinGroup">
          <!-- 加入 -->
          {{ $t("chatter.group.join") }}
        </el-button>
      </el-dialog>

      <!-- 请选择需要使用的模板 -->
      <el-dialog
        :visible.sync="chooseTemplateidFlag"
        :title="$t('c1053')"
        top="15%"
        width="27%"
        :close-on-click-modal="false"
        :before-close="chooseTemplateidClose"
      >
        <span style="font-size: 16px; font-weight: bold">{{
          dialogAttr.content
        }}</span>
        <el-select
          v-model="chooseTemplateid"
          :placeholder="$t('label.searchs')"
          style="width: 80%"
          size="mini"
        >
          <el-option
            v-for="item in templateList"
            :key="item.templateid"
            :label="item.templatename"
            :value="item.templateid"
            :disabled="item.isenable !== '1'"
          >
          </el-option>
        </el-select>
        <span slot="footer" class="dialog-footer">
          <el-button @click="chooseTemplateidClose" size="mini">
            <!-- 取消 -->
            {{ $t("button_partner_cancel") }}
          </el-button>
          <el-button @click="chooseTemplateidconfirm" type="primary" size="mini">
            <!-- 确认 -->
            {{ $t("label_chatter_ok") }}
          </el-button>
        </span>
      </el-dialog>
      <!-- 删除数据和解除锁定 -->
      <el-dialog
        :visible.sync="dialogVisible"
        :title="dialogAttr.title"
        top="15%"
        width="27%"
        :close-on-click-modal="false"
        :before-close="handleClose"
      >
        <span style="font-size: 16px; font-weight: bold">
          {{ dialogAttr.content }}</span
        >
        <span slot="footer" class="dialog-footer">
          <el-button @click="dialogVisible = false" size="mini">
            {{ $t("button_partner_cancel") }}
          </el-button>
          <el-button @click="confirm" type="primary" size="mini">
            {{ $t("label_chatter_ok") }}
          </el-button>
        </span>
      </el-dialog>
      <!-- 订单详情页生成发票弹窗 -->
      <create-edit-obj
        ref="orderCeateEditObj"
        :object-name="orderNameRecive"
        :prefix="orderPrefix"
        :objectApi="orderApi"
        :isOrderPage="true"
        :customId="customId"
        :orderInfo="orderInfo"
        :opportunityInfo="opportunityInfo"
        @save="save"
      ></create-edit-obj>
      <!-- 伙伴云 -->
      <el-dialog
        custom-class="partnercloud"
        :title="$t('label_partner')"
        :visible.sync="isPartnerCloud"
        width="69.3%"
        :before-close="handleCloses"
      >
        <el-table :data="tableData" border style="width: 100%">
          <!-- 名称 -->
          <el-table-column
            prop="name"
            :label="$t('label_chatter_name')"
            width="180"
          ></el-table-column>
          <!-- 描述 -->
          <el-table-column
            prop="description"
            :label="$t('label.file.des')"
            width="180"
          ></el-table-column>
          <el-table-column label="URL">
            <template slot-scope="scope">
              <el-link type="primary" :href="scope.row.url" target="_blank">
                {{ scope.row.url }}
              </el-link>
            </template>
          </el-table-column>
        </el-table>
        <span slot="footer" class="dialog-footer">
          <el-button @click="isPartnerCloud = false">
            <!-- 取 消 -->
            {{ $t("button_partner_cancel") }}
          </el-button>
        </span>
      </el-dialog>
      <!-- 置顶按钮 -->
      <svg class="icon backtop" aria-hidden="true" @click="backtop">
        <use href="#icon-detail-top1"></use>
      </svg>
      <div style="height: 0; overflow: hidden">
        <ApprovalHistory
          v-show="true"
          ref="uponDetailHistory"
          :historyID="id"
          :itemTitle="fieldValue"
          @closeLoading="closeLoading"
        ></ApprovalHistory>
      </div>
      <!-- 回退公海池弹框-->
      <seas-pool
        :seaShow.sync="seaShow"
        :objid="valSea"
        @clientPoolCancle="clientPoolCancle"
        :objectApi="objectApi"
      ></seas-pool>
      <!-- 公告发布范围弹框 -->
      <ggReleaseRange
        :ggRecordid="id"
        :isShowggRecord="isShowggRecord"
        @closeGgRecord="closeGgRecord"
      ></ggReleaseRange>

      <!-- 迁移资产 -->
      <AssetsMoveDialogVisible
        ref="assetsBOM"
        :prefix="prefix"
        :id="id"
        :object-api="objectApi"
        :object-name="tabName"
        :dataListPartner="dataList"
        @updateDetail="refreshAll"
      />
      <!-- 删除文章、删除草稿、归档、还原、作为草稿编辑 -->
      <Dialog
        :dialogSign="dialogSign"
        :btnName="btnName"
        :titleType="titleType"
        :jumpsign="jumpsign"
        @dialogEdit="dialogEdit"
        @dialoghandleClose="dialoghandleClose"
      >
        <template v-slot:Dialog>
          <div>
            <div v-if="btnSign == 'draftdel'">
              <!-- "您无法恢复已删除的草稿。"
             "现有草稿翻译将链接到主语言文章的上一个发布版本。如果没有发布版本，将删除任何译稿。" -->
              <div class="dialog-type">
                {{ $t("label.knowledgebase.unable.restore.draft") }}
              </div>
              <div>
                {{
                  $t(
                    "label.knowledgebase.delete.translation.associated.with.draft"
                  )
                }}
              </div>
            </div>
            <div v-else-if="btnSign == 'articledel'" class="dialog-type">
              <!-- "删除已归档文章会将其和所有关联版本从知识库中永久移除。" -->
              <div>
                {{ $t("label.knowledgebase.permanently.remove.articles") }}
              </div>
            </div>
            <div v-else-if="btnSign == 'file'" class="dialog-type">
              <!-- "已发布翻译也将归档，将删除处于草稿状态的翻译。" -->
              <div>{{ $t("label.knowledgebase.published.translations") }}</div>
            </div>
            <div v-else-if="btnSign == 'recovery'" class="dialog-type">
              <!-- "还原会从此版本创建草稿。您将需要发布草稿，以使其可用。" -->
              <div>{{ $t("label.knowledgebase.restore.creates.draft") }}</div>
            </div>
            <div v-else-if="btnSign == 'draftedit'" class="dialog-type">
              <!-- "已发布版本仍将在线，直到您发布此草稿。" -->
              <div>{{ $t("label.knowledgebase.published.version.online") }}</div>
            </div>
          </div>
        </template>
      </Dialog>
      <!-- 知识库发布 -->
      <Release
        :id="id"
        :transfer-owner-dialog="releasedialogVisible"
        @releasehandleClose="releasehandleClose"
        :markNewVersion="markNewVersion"
        :affectTranslation="affectTranslation"
        :jumpsign="jumpsign"
        @refreshall="refreshall"
      ></Release>
      <!-- 销售云 报价表 -->
      <CreatedQuotationDialog
        ref="createdQuotationDialog"
        :id="id"
        :objId="objId"
        :type="printType"
        :title="printTitle"
        :templateList="templateList"
        :quotationDialogVisible="quotationDialogVisible"
        @closeQuotaionDialog="closeQuotaionDialog"
      ></CreatedQuotationDialog>
      <!-- 销售云 选择报价单模板   -->
      <SelectTemplate
        ref="selectTemplate"
        :id="id"
        :objId="objId"
        :templateList="templateList"
        :selectTemplateDialogVisible="selectTemplateDialogVisible"
        @closeSelectTemplateDialog="closeSelectTemplateDialog"
      ></SelectTemplate>
      <!-- 销售云 选择报价单文件 -->
      <selectquotation
        :id="id"
        :title="this.eventTap || this.tabName"
        ref="selectquotation"
        :dialogTableVisible="dialogTableVisible"
        @closedselectquotation="closedselectquotation"
        @opencreatedquo="opencreatedquo"
      >
      </selectquotation>
      <!-- 发送邮件页面 -->
      <SendEmailDetail
        :enclosurearr="enclosurearr"
        :dialogVisibleEmail="dialogVisibleEmail"
        @closeEmailDialog="closeEmailDialog"
        :emailIsOperation="emailIsOperation"
        :templateId="templateId"
        :recordId="id"
        :objId="objId"
        :invoiceReceiver="invoiceReceiver"
        ref="SendEmailDetail"
      ></SendEmailDetail>
      <!-- 线上支付 -->
      <InvoiceTemplate
        ref="invoiceTemplate"
        :id="id"
        :objId="objId"
        :title="this.eventTap || this.tabName"
        :templateList="templateList"
        :selectTemplateDialogVisible="invoiceTemplateDialogVisible"
        @closeSelectTemplateDialog="closeinvoiceTemplateDialog"
      ></InvoiceTemplate>
      <!--    条形码，二维码弹窗-->
      <code-dialog ref="codeStyleDialog"> </code-dialog>
      <div class="knowledge">
        <el-dialog
          title="编辑文章类别"
          :visible.sync="centerDialogVisible"
          width="50%"
          center
          :before-close="checkcancel"
        >
          <div class="type">
            <div class="typetree">
              <el-input placeholder="输入关键字进行过滤" v-model="filterText">
              </el-input>
              <el-tree
                style="border: 1px solid #e5e5e5; min-height: 280px"
                class="filter-tree"
                :data="fromData"
                :props="defaultProps"
                default-expand-all
                :show-checkbox="true"
                :indent="10"
                :check-strictly="true"
                node-key="id"
                :filter-node-method="filterNode"
                @check="handleCheckChange"
                ref="tree"
              >
              </el-tree>
            </div>
            <!-- <div style="width:45%">
          <div>已选类别</div>
          <div style="height:320px;border:1px solid #e5e5e5;width:100%">
            <el-tree :data="checkoutlist" :props="defaultProps" :indent="10" :default-expand-all="true"></el-tree>
          </div>
        </div>   -->
          </div>
          <span slot="footer" class="dialog-footer">
            <!-- 取 消 -->
            <el-button @click="checkcancel">{{
              $t("label.emailsync.button.cancel")
            }}</el-button>
            <!-- 确 定 -->
            <el-button type="primary" @click="checksure">{{
              $t("component.button.ok")
            }}</el-button>
          </span>
        </el-dialog>
      </div>
      <div class="QBslowCC" v-if="QBtfCC">
        <el-button
          :autofocus="true"
          :loading="true"
          style="background: none; border: none; color: #e6a23c"
          >{{ QBmessCC }}</el-button
        >
      </div>
      <!-- 发票对象下是否确认收获弹窗 -->
      <el-dialog
        :title="$t('label_popup_infoz')"
        :visible.sync="invoicesDialogVisible"
        width="35%"
      >
        <!-- {{$t('c65')}} -->
        <span style="font-size: 16px; font-weight: bold">{{ $t("c65") }}</span>
        <span slot="footer">
          <div>
            <!-- 取 消 -->
            <el-button @click="invoicesDialogVisible = false">{{
              $t("label.emailsync.button.cancel")
            }}</el-button>
            <!-- 确 定 -->
            <el-button type="primary" @click="updateStatus">{{
              $t("component.button.ok")
            }}</el-button>
          </div>
        </span>
      </el-dialog>
      <!-- 业务机会对象下  新建报价单 订单  发票弹窗 -->
      <create-edit-obj
        ref="opportunityCeateEditObj"
        :object-name="opportunityNameRecive"
        :prefix="opportunityPrefix"
        :objectApi="opportunityApi"
        :orignObj="orignObj"
        :isSpecialCreate="true"
        :opportunityInfo="opportunityInfo"
        :opportunitySecInfo="opportunitySecInfo"
      ></create-edit-obj>
      <!-- 展示所有标签的弹框 -->
      <el-dialog
        title="查看标签"
        :visible.sync="showAllTags"
        top="15%"
        width="373px"
        :before-close="handleCloseAllTags"
      >
        <div class="allTagDialog">
          <el-tag
            v-for="item in labelArr"
            :key="item.id"
            :type="item.type"
            effect="plain"
            style="cursor: pointer"
            :style="{
              background:
                item.color === 'hong'
                  ? hong
                  : item.color === 'huang'
                  ? huang
                  : item.color === 'lan'
                  ? lan
                  : item.color === 'hui'
                  ? hui
                  : item.color === 'lv'
                  ? lv
                  : '',
              color: color,
            }"
            >{{ item.name }}</el-tag
          >
        </div>
      </el-dialog>
      <!--报价单详情页新建发票按钮提示弹窗 -->
      <el-dialog
        :title="$t('label_popup_infoz')"
        width="40%"
        top="5%"
        :visible.sync="showQuoteIntro"
        append-to-body
        :close-on-click-modal="false"
        @close="cancelChange"
      >
        <div style="font-size: 16px; font-weight: bold; word-break: break-word">
          {{ $t("c835") }}
        </div>
        <span slot="footer">
          <el-button @click="cancelChange" size="mini">
            <!-- 取消 -->
            {{ $t("label.cancel") }}
          </el-button>
          <el-button @click="confirmChange" type="primary" size="mini">
            <!-- 删除 -->
            {{ $t("label.confirm") }}
          </el-button>
        </span>
      </el-dialog>
    </div>
  </template>

  <script>
  import { Bind } from "../../api";
  import * as detailApi from "../../api.js";
  import detailSendCard from "@/utils/robotMessage.js";
  import * as CommonObjApi from "../../detailChild/api.js";
  import SendEmailDetail from "@/views/quotationEmail/components/sendEmailDetail.vue";
  import SelectTemplate from "@/views/quotationEmail/components/selectTemplate.vue";
  import InvoiceTemplate from "@/views/invoice/selectTemplate.vue";
  import dynamicPublic from "../../../colleagueCircle/components/dynamic-public.vue";
  import ApprovalHistory from "@/views/approval/components/approval-history.vue";
  import seasPool from "../../detailChild/seasPool.vue";
  import { Share, PdfViewer, Release, Dialog } from "@/components/index.js";
  import job from "@/views/chat/js/request.js";
  import synchro from "../../../../components/synchro/index";
  import ImportantEvent from "@/views/commonObjects/detailChild/ImportantEvent/ImportantEvent.vue";
  import createEditObj from "../../components/create-edit-obj.vue";
  import VueCookies from "vue-cookies";
  import debounce from "lodash.debounce";
  import * as Time from "@/utils/time";
  import ggReleaseRange from "../../components/ggReleaseRange.vue";
  import AssetsMoveDialogVisible from "../../components/Assets-move-dialog.vue"; //迁移资产
  import CodeDialog from "../../components/CodeDialog.vue"; //资产二维码
  import CreatedQuotationDialog from "@/views/quotationEmail/components/createdQuotationDialog.vue";
  import selectquotation from "@/views/quotationEmail/components/selectquotation.vue";
  import DialogMixin from "@/mixin/Dialog.js";
  import nextApproval from "@/views/approval/components/approval-dialog";
  import { AddFiles } from "@/components/index";
  import PdfTemplate from "./pdfTemplate.vue";
  import list from "./list.vue";

  export default {
    mixins: [DialogMixin],
    name: "CommonDetail",
    components: {
      InvoiceTemplate,
      SelectTemplate,
      CreatedQuotationDialog,
      selectquotation,
      Share,
      PdfViewer,
      synchro,
      createEditObj,
      ApprovalHistory,
      ImportantEvent,
      seasPool, //回退公海池弹框
      ggReleaseRange, //公告发布范围弹框
      AssetsMoveDialogVisible, //迁移资产
      CodeDialog, //资产二维码
      Release, //发布
      SendEmailDetail, //发送邮件
      Dialog, //删除文章、删除草稿、归档、还原、作为草稿编辑
      nextApproval, //下个审批人弹窗
      dynamicPublic, //动态
      AddFiles, //添加文件
      PdfTemplate, //PDF模板
      list,
    },
    props: {
      dataId: {
        type: String,
        default: "",
      },
      // 客户数据
      accountvalue: {
        type: Array,
        default: () => [],
      },
    },
    data() {
      return {
        // 更改布局模板按钮
        templateBtn: [
          {
            name: this.$i18n.t("pagecreator.page.button.edit"),
            id: "edit",
          },
          {
            name: this.$i18n.t("label.document.replaces"),
            id: "replace",
          },
        ],
        //PDF模板数据
        templatePdf: "",
        // 模板tpye
        templateType: "",
        // PDF模板loading
        pdfTemplateLoad: true,
        //按点击钮每个菜单数据
        relatedListObj: {},
        dialogNewAttr: {
          // 新建编辑
          title: "",
          type: "NEW",
          isShow: false,
          dialogWidth: "85%",
          id: "",
        },
        seeViewType: true,
        addFileDialogVisible: false, //添加文件弹框
        dialogAddVisible: false, //文件弹框
        isLocked: false, //是否锁定
        isEdit: false, // 编辑权限
        list: [], //相关列表数据
        // tab选中对象，默认动态
        activeName: "",
        // 禁止点击当前dom
        noClick: false,
        orignObj: "",
        showQuoteIntro: false, //报价单详情页新建发票按钮提示弹窗是否展示
        opportunityInfo: {},
        opportunitySecInfo: [],
        opportunityNameRecive: "",
        opportunityPrefix: "",
        opportunityApi: "",
        relatedlistIds: "",
        invoicesDialogVisible: false,
        orderInfo: {},
        //提交待审批loading
        submitStatus: false,
        //发票收件人邮箱
        invoiceReceiver: "",
        //发票客户名称
        accountReceiver: "",
        //发票币种
        invoiceCurrency: "",
        //发票id
        templateId: "",
        customId: "",
        invoiceInfo: {},
        invoicePrefix: "072",
        orderPrefix: "021",
        invoiceApi: "cloudccproceed",
        orderApi: "Invoices",
        //预览页面title
        printTitle: "报价单预览",
        //模板预览pdf类型 quotation：报价单 invoice：发票
        printType: "quotation",
        closeEmailDialogFlg: 0,
        templateList: [], //模板列表
        selectTemplateDialogVisible: false, //选择模板
        invoiceTemplateDialogVisible: false, //发票选择模板
        layoutid: "", //布局id
        isShowdynamicPublics: "", // 是否启用微贴
        showPayButton: false, //是否展示付款按钮
        isOpenAssetBom: false, // 资产BOM权限判断是否开启
        isShowggRecord: false, //公告菜单下  标准按钮更改成 发布范围按钮 wzj  勿删勿删勿删
        isOpenService: sessionStorage.getItem("isOpenService"),
        valSea: "",
        seaShow: false, //回退公海池
        theme: "", // 主题value
        detailLayoutMode: "mode1",
        //是否为伙伴云用户
        Partnercloud: this.$store.state.userInfoObj.ispartner,
        orgId: this.$store.state.userInfoObj.orgId,
        //群策按钮是否显示
        isShowChat:
          VueCookies.get("countryCode") == "CN" &&
          localStorage.getItem("chatOpen") == "true" &&
          localStorage.getItem("login") == "success",
        dialogAttr: {
          title: "",
          content: "",
        },
        inlineedit: false,
        dynamicType: false, //更改右侧动态tab
        isShowType: true, //判断用户详情页面
        qcImg: "#icon-qc",
        qunceType: "",
        //加入群策需要的数据
        qunceData: {},
        //显示加入群策弹框
        isShowTag: false,
        //当前群策的群ID
        detailGroupId: "",
        //客户群策所有人
        groupStrategyOwnerid: {}, //获取用户的环信ID
        information: {},
        detail: "detail",
        content: this.$i18n.t("vue_label_commonobjects_detail_company_hierarchy"), //公司层级结构
        isCustomer: false,
        isShowAssets: false, //查看资产BOM
        customFlag: false,
        icons: "el-icon-d-arrow-right",
        isHeight: false,
        height: "100%",
        active: 0,
        isTrue: "active",
        dialogFormVisible: false,
        formLabelWidth: "120px",
        labelName: [],
        labelArr: [],
        buttonList: [],
        heightLight: [],
        // showBtn: false,
        moreButton: [],
        showButton: [],
        showEditButton: {}, ///编辑按钮的数据
        showPdfButton: {}, //pdf按钮的数据
        showMailButton: {}, //邮件按钮数据
        lockingButton: {}, //锁定按钮数据
        color: "#fff",
        hong: "#F6817B",
        huang: "#FEC366",
        lan: "#62A5F3",
        hui: "#C8C6C6",
        lv: "#7cedcc",
        id: this.$route.params.id || this.dataId,
        objectApi: this.$route.query.objectApi,
        ewobjectApi: "", // 企业微信转联系人或潜在客户专用
        bigTitle: [], //对象记录编号
        fieldValue: "",
        tabName: "",
        ewtabName: "", // 企业微信转联系人或潜在客户专用
        ewId: {}, // 企业微信转联系人或潜在客户专用
        ewData: {}, // 企业微信转联系人或潜在客户专用
        ewprefix: "", // 企业微信转联系人或潜在客户专用
        shares: [],
        follow: "",
        isRlation: "List", //PDF视图只有列表式
        tabData: [],
        pdfViewerAttr: {
          title: this.$i18n.t("pagecreator_button_preview"), //"预览",
          isShow: false,
          pdfUrl: null,
        },
        objId: this.$route.query.objId,
        prefix: this.$route.query.prefix,
        // 右上角审批按钮
        showHistoryBtn: [],
        hideHistoryBtn: [],
        printVisible: false, //详情打印弹窗
        isconvert: "",
        viewId: this.$route.query.viewId,
        objArr: [],
        pricebookArr: [],
        isQuery: null, // 记录可查权限
        isCanEditOwner: "", //判断是否有更改所以人权限
        isTag: "",
        routerName: "",
        activeList: [],
        postShowType: "detailInfo", //查询帖子的类型
        recordName: "",
        copyType: "",
        userName: "",
        dialogVisible: false, //删除弹窗给
        deletDialogVisible: false,//删除相关列表中的数据
        childrenOptions: [
          // 列表
          {
            type: "list",
            options: {
              row: 2,
              column: 3,
              width: "80px",
              height: "16px",
              backgroundColor: "#f3f2f2",
              justifyContent: "center",
              active: false,
              itemRowMargin: "8px 8px 8px 26px",
              firstWidth: "50px",
              lastWidth: "80px",
              alignItems: "center",
            },
          },
        ],
        isSkeleton: false,
        listObjectApi: "",
        listPrefix: "",
        pageFlag: "",
        isBatchLoading: false,
        batchAddDialogTitle: this.$i18n.t("label.object.batchadd", {
          objName: this.tabNameData,
        }),
        listObjectId:'',
        styleOption: [
          // 列表
          {
            type: "list",
            options: {
              row: 2,
              column: 2,
              width: "44.9vw",
              height: "20px",
              backgroundColor: "#f3f2f2",
              justifyContent: "center",
              active: false,
              itemRowMargin: "10px 28px",
              firstWidth: "44.9vw",
              lastWidth: "44.9vw",
              alignItems: "center",
            },
          },
        ],
        isStyleType: false,
        partner: [],
        ismanagePartnerUser: null,
        ispartner: null,
        dataList: [],
        isPartnerCloud: false,
        tableData: [],
        userId: "",
        tabStyle: "",
        isShowGanttButton: false, //控制显示甘特图按钮
        screenWidth: document.body.clientWidth,
        timer: false,
        myid: localStorage.getItem("myid"),
        realObjId: "", //对象的objId
        dateFormat: Time.dateFormat(this.$cookies.get("countryCode")),
        datetimeFormat: Time.datetimeFormat(this.$cookies.get("countryCode")),
        timeFormat: Time.timeFormat(this.$cookies.get("countryCode")),
        countryCode: this.$cookies.get("countryCode"),
        operatingTimeObj: {}, // 服务区域下的操作时间
        twitterImg: "#icon-twitter",
        linkedinImg: "#icon-Linkedin",
        facebookImg: "#icon-facebook",
        twitterImgBlue: "#icon-twitter_blue",
        linkedinImgBlue: "#icon-Linkedin_blue",
        facebookImgBlue: "#icon-facebook_blue",
        underDetailSubmitStatus: true, //提交待审批状态
        langeoptions: [],
        langeoptionslength: "",
        langevalue: "",
        centerDialogVisible: false, //知识库分类
        fromData: [],
        fromcheck: [],
        toData: [],
        thumbsUpvalue: 0,
        thumbsDownvalue: 0,
        data: [],
        defaultProps: {
          children: "children",
          label: "label",
        },
        jumpsign: true, //草稿删除标识
        releasedialogVisible: false, //知识文章发布
        affectTranslation: null,
        markNewVersion: null,
        editQuoteline: "", //编辑报价行按钮
        editDiscountTiers: "", //编辑阶梯折扣按钮
        filterText: "",
        checkoutlist: [],
        dataNum: 0,
        allon: false,
        briefHeight: 0, //简介的高度
        typedisabled: "",
        fullscreenLoading: false,
        quotationDialogVisible: false,
        dialogTableVisible: false,
        //发送邮件弹框是否显示
        dialogVisibleEmail: false,
        //发送邮件弹框是否展示操作项
        emailIsOperation: true,
        is_master_version: "", //文章是否为主版本
        customColors: [
          { color: "#70acf1", percentage: 20 },
          { color: "#f7c97e", percentage: 40 },
          { color: "#faa58c", percentage: 100 },
        ],
        enclosurearr: [], //要发送的报价单文件
        // 客服云对象新增参数
        prefixCustomer: "",
        objectApiCustomer: "",
        objectNameCustomer: "",
        objectNameReceive: this.$i18n.t("label.mobile.phone.receivable"),
        orderNameRecive: "",
        QBtfCC: false,
        QBmessCC: "",
        isquick: null, //QB是否启用
        showAllTags: false, //展示所有标签的弹窗
        // 知识库展示查看翻译空值
        blankValue: "",
        // 记录类型
        recordtype: "",
        subid: "", //模板使用的id
        templatename: "", //模板名称
        chooseTemplateid: "", //更改模板id
        chooseTemplateidFlag: false, //选择模板弹框的开关
        templateStatus: "", //模板的状态
        templateEditBtn: false, //模板操作按钮
        objectApiName: "", //对象名称
        objectApiNumber: "", //对象编号
        templateData: [], //PDF模板数据
        tabNameData: "",
        editObjid: "", //新建编辑页id
        objectApiData: this.$route.query.objectApi,
        prefixData: "",
        relationFieldId: "",
        relativeRecordId: "",
      };
    },
    beforeRouteEnter(to, from, next) {
      if (VueCookies.get("detailLayoutMode") === null) {
        VueCookies.set("detailLayoutMode", "mode1", { sameSite: 'Strict' });
      }
      next((vm) => {
        // 通过 `vm` 访问组件实例
        vm.detailLayoutMode = VueCookies.get("detailLayoutMode");
        // vm.$refs.listChild && vm.$refs.listChild.setRelationNameWidth()
      });
    },
    created() {
      this.isSkeleton = true;
      this.routerName = this.$route.query.oprateType;
      this.viewId = this.$router.history.current.query.viewId;
      this.init();
    },
    mounted() {
      this.$bus.$on("updateDetailRecordId", () => {
        this.$CCDK.CCDetail.setDetailId(this.id) // 详情页记录ID   给自定义页面内嵌使用
      });
      //选择下个审批人弹窗
      if (this.$route.query.isManual) {
        this.$refs.nextApprovalref.IsShowdialog(true, this.$route.query.recordId);
        //提交待审批只执行一次，防止刷新再次执行，清除路由参数
        this.$router.push({ query: { objId: this.$route.query.objId } });
      }
      this.$CCDK.CCDetail.setDetailId(this.id) // 详情页记录ID   给自定义页面内嵌使用
      // 知识文章详情页查看翻译
      if (this.id.substring(0, 3) === "k02") {
        this.findArticleIdTranslate();
        this.queryDataCategorySelection();
      }
      this.getIsHasGroup();
      this.GetRelationList(true);
      // 如果是资产，获取用户资产操作权限
      if (this.id.substring(0, 3) === "s42") {
        this.getAssetBomPermission();
      }

      if (VueCookies.get("detailLayoutMode") === null) {
        VueCookies.set("detailLayoutMode", "mode1", { sameSite: 'Strict' });
      }
      this.detailLayoutMode = VueCookies.get("detailLayoutMode");
      this.$Bus.$on("deliver-group-strategy-ownerid", (res) => {
        this.groupStrategyOwnerid = res;
      });
      // 服务区域下的操作时间
      this.$Bus.$on("service-territory-data", (res) => {
        this.operatingTimeObj = res;
      });
      this.$Bus.$on("chooseListItem", (val) => {
        if (this.objectApi == "quote") {
          this.templateStatus = val?.zhuangtai;
          this.$store.commit("setRecordStatus", val?.zhuangtai);
        } else {
          this.templateStatus = val?.status;
          this.$store.commit("setRecordStatus", val?.status);
        }
        this.objectApiNumber = val?.name;
        this.$store.commit("setObjectNumber", val?.name);
      });
      // 状态为空时，对模板状态赋值
      if(!this.templateStatus&&this.$store.getters.getRecordStatus){
        this.templateStatus = this.$store.getters.getRecordStatus;
      }
      this.objectApiNumber = this.$store.getters.getObjectNumber;
      // setObjectNumber
      // 恢复提交待审批状态
      this.$Bus.$on("recovery-submit-status", () => {
        this.underDetailSubmitStatus = true;
      });
      //获取窗口宽度
      this.$bus.$on("windowResize", (offsetHeight, offsetWidth) => {
        this.screenWidth = offsetWidth;
      });
      //提交待审批后局部刷新页面
      this.$Bus.$on("approvalrefresh", () => {
        this.refreshAll();
      });
      this.getSetupInfo();
    },
    beforeRouteUpdate (to, from, next) {
      this.$refs.listChild.id=to.params.id;
      this.$refs.listChild.init();
      next();
    },
    methods: {
      // 点击PDF图标下载模板文件
      getDownPdfFile(){
        detailApi.getTemplatePdf({
          id: this.templateId,
          objid: this.objId,
          name: this.templatename,
          type: this.templateType,
          recordId: this.id,
          }).then((res) => {
            if (res.result) {
              let link = document.createElement("a");
              link.href = 'data:application/pdf;base64,' +res.data.templatePdf;
              link.setAttribute("download", this.templatename); // 下载文件的名字
              document.body.appendChild(link);
              link.click();
            } else {
              this.$message({
                showClose: true,
                type: "error",
                message: res.returnInfo,
              });
            }
          });
      },
      // 鼠标进入PDF视图模板区域
      mouseTemplate() {
        this.templateEditBtn = true;
      },
      // 鼠标离开PDF视图模板区域
      leaveTemplate() {
        this.templateEditBtn = false;
      },
      // 修改模板确定按钮
      chooseTemplateidconfirm() {
        this.templateList.map((item) => {
          if (item.templateid == this.chooseTemplateid) {
            this.templatename = item.templatename;
          }
        });
        this.templateId = this.chooseTemplateid;
        this.chooseTemplateidFlag = false;
        this.pdfTemplateLoad = true;
        this.getTemplateInfo();
      },
      // 关闭选择模板的弹框
      chooseTemplateidClose() {
        this.chooseTemplateidFlag = false;
      },
      // 点击更改布局模板按钮
      chooseTemplateBtn(value) {
        // 页面跳转至模板打印设置中，当前模板的编辑页面
        if (value == "edit") {
          this.$router.push({
            path: "/systemSettings/setuppage/TemplateDetail",
            query: {
              Tid: this.templateId, //模板id
              Tsubid: this.subid, //subid
              TobjId: this.objId,
              Tname: this.templatename,
              Type: this.templateType,
            },
          });
        }
        // 点击之后，弹窗展示模板打印设置中，该对象下与当前的记录类型一致，下拉选择，点击确认后，刷新当前详情页（整个页面刷新），展示新的模板
        if (value == "replace") {
          this.chooseTemplateid = this.templateId;
          detailApi.getObjTemplateList({ objId: this.objId }).then((res) => {
            if (res.result) {
              this.templateList = res.data;
            }
          });
          this.chooseTemplateidFlag = true;
        }
      },
      // 相关列表名称拼接数量
      openApproval(i, detailGroupId, recordId) {
        if (i == 0) {
          if (this.approvalSubmitStatus) {
            this.approvalSubmitStatus = false;
            if (
              this.$refs.relationListObj?.objectInfo.relatedlistType ===
              "approvalHistory"
            ) {
              this.$refs.relationListObj.handleHistory(
                i,
                detailGroupId,
                recordId
              );
            }
          } else {
            // this.$message.warning('等一下')
          }
        } else {
          if (
            this.$refs.relationListObj?.objectInfo.relatedlistType ===
            "approvalHistory"
          ) {
            this.$refs.relationListObj.handleHistory(i, detailGroupId, recordId);
          }
        }
      },
      // 模板数据接口
      getTemplateInfo() {
        let param = {
          id: this.templateId, //模板id
          objid: this.objId, //对象id
          type: this.templateType, //模板类型
          recordId: this.id, //记录id
        };
        detailApi
          .getTemplateInfo(param)
          .then((res) => {
            // type==list的是相关列表数据，展示成表格数据
            let templateTable = [];
            let templateHeader = [];
            res.data.secList.map((item) => {
              if (item.type == "list" && item.fields.length > 0) {
                item.fields.map((fieldsitem, fieldsindex) => {
                  // 表格中表头的数据
                  templateHeader.push({
                    label: fieldsitem.labelName,
                    prop: fieldsitem.schemefieldName,
                  });
                  //   表格数据
                  fieldsitem.fieldValue?.map((value, index) => {
                    // 例如：销售价格等字段，接口可能返回两种币种数据，只展示一种数据并去掉币种
                    //USD 5,060.00 ||USD 5,060.00(USD 6254) == 5,060
                    let newValue = value;
                    if (value.indexOf("(") > -1) {
                      let spliceNum = value.indexOf("("); //首次出现（的位置
                      let currencyData = value.substring(0, spliceNum);
                      newValue = currencyData.replace(/[a-zA-Z]+/g, "");
                    }else{
                      newValue = newValue.replace(/[a-zA-Z]+/g, "");
                    }

                    if (fieldsindex == 0) {
                      templateTable.push({
                        [fieldsitem.schemefieldName]: newValue,
                      });
                    } else {
                      templateTable[index] = Object.assign(templateTable[index], {
                        [fieldsitem.schemefieldName]: newValue,
                      });
                    }
                  });
                });
                // 表格中有产品和描述时，合并为一个字段
                // 订单字段名：product2id和description
                if (
                  (templateHeader[0].prop == "chanpin" &&
                    templateHeader[1].prop == "hxmms") ||
                  (templateHeader[0].prop == "product2id" &&
                    templateHeader[1].prop == "description")||
                    (templateHeader[0].prop == "plan" &&
                    templateHeader[1].prop == "description")
                ) {

                  templateHeader[0].label = this.$i18n.t("vue_label_normal_product_sf")+'&'+this.$i18n.t("label.appointment.wizard.subtitle6");

                  templateTable.map((item) => {
                    if (
                      templateHeader[0].prop == "chanpin" &&
                      templateHeader[1].prop == "hxmms"
                    ) {
                      item.chanpin = item.chanpin + "\n" + item.hxmms;
                    } else if (
                      templateHeader[0].prop == "product2id" &&
                    templateHeader[1].prop == "description"
                    ) {
                      item.product2id = item.product2id + "\n" + item.description;
                    }else if (
                      templateHeader[0].prop == "plan" &&
                    templateHeader[1].prop == "description"
                    ) {
                      item.plan = item.plan + "\n" + item.description;
                    }
                  });
                }
                templateHeader.splice(1, 1);
                item.templateTable = templateTable;
                item.templateHeader = templateHeader;
              }
              // 客户名称字段添加id
              if (item.type == "section" && item.fields.length > 0) {
                item.fields.map((account) => {
                  if (
                    account.schemetable_id == "account" ||
                    account.schemefieldName == "account_name"
                  ) {
                    this.accountvalue.find((accountitem) => {
                      if (accountitem.id == this.id) {
                        account.khmc = accountitem.khmc;
                      }
                    });
                  }
                  // 总计进行换行显示
                  if (
                    account.apiname == "totalamount" ||
                    account.apiname == "total" ||
                    account.apiname == "grandtotal"
                  ) {
                    let index = account.fieldValue.indexOf("(");
                    if(index>-1){
                      account.fieldValue =
                      account.fieldValue.substring(0, index) +
                      "<br/>" +
                      account.fieldValue.substring(index);
                    }
                  }
                });
              }
            });
            this.templateData = res.data.secList;
            this.pdfTemplateLoad = false;
          })
          .catch(() => {
            this.pdfTemplateLoad = false;
          });
      },
      // 切换tab
      handleClick() {
        if(this.activeName!='second'){
          this.relatedlistIds=this.activeName;
        }
      },
      // 查看经典视图
      SeeOldView() {
        let param = {
          obj: this.objectApi,
          enable: this.seeViewType.toString(),
        };
        detailApi.saveTemplateViewStatus(param).then(() => {
          // 切换PDF视图时，IsPdfViewStatus清空
          this.$store.commit("setIsPdfViewStatus", "");
          this.$router.push(`/commonObjects/detail/${this.id}/DETAIL`);
        });
      },
      // 特殊相关列表进入主记录编辑页
      goToDetail(objectApi, objId) {
        this.$nextTick(() => {
          this.$refs.createEditObj.objectApi = objectApi;
          this.$refs.createEditObj.objId = objId;
          this.$refs.createEditObj.edit();
        });
      },
      //相关列表新建、批量新增显示方法
      newDisplayFun(item) {
        let addBtn = item.buttons.find((btn) => {
          return btn.name === "New";
        });
        let batchAddBtn = item.buttons.find((btn) => {
          return btn.name === "Batch Added";
        });
        // 订单对象 发票相关列表 新建按钮走订单生成发票按钮的展示逻辑
        if (
          item.objid === "invoices" &&
          item.recordObjId === "20210525cloudccorder"
        ) {
          CommonObjApi.orderStatus({ orderid: this.id }).then((res) => {
            if (
              res.data[0].invoicestatus !== "已开具发票" &&
              res.data[0].invoicestatus !== "All Invoiced"
            ) {
              addBtn &&
                item.showButton.push({
                  id: "",
                  label: this.$i18n.t("label.ems.create"), //"新建",
                });
            }
          });
        } else {
          addBtn &&
            item.showButton.push({
              id: "",
              label: this.$i18n.t("label.ems.create"), //"新建",
            });
        }
        batchAddBtn &&
          item.showButton.push({
            id: "",
            label: this.$i18n.t("label.batchadd"), //"批量新增",
          });
      },
      //添加文件
      addWorkClose(n) {
        if (n) {
          this.$emit("refreshUpload");
          this.dialogAddVisible = false;
        } else {
          this.dialogAddVisible = false;
        }
      },
      // 提交文件
      addWorkSubmit(checkedFileList) {
        let data = [];
        checkedFileList.map((item) => {
          let dataItem = {};
          dataItem.recordid = this.id;
          dataItem.name = item.name;
          dataItem.type = item.fileType ? item.fileType : item.type;
          dataItem.fileContentId = item.fileContentId || item.id; //下载文件2021开头的是filecontentid
          dataItem.fileinfoid = item.fileInfoId || item.id; // fil开头的是fileinfoid
          dataItem.filesize = item.filesize;
          data.push(dataItem);
        });
        Bind(JSON.stringify(data)).then((res) => {
          let self = this;
          if (res.result === true) {
            //群策卡片
            for (let i = 0; i < res.data.length; i++) {
              if (res.data[i].cardValue) {
                (function (i) {
                  setTimeout(async () => {
                    let fieldsList = [];
                    res.data[i].cardValue.fieldsList.forEach((item) => {
                      if (
                        fieldsList.length < 5 &&
                        item.fieldValue != null &&
                        item.fieldValue != "true" &&
                        item.fieldValue != "false" &&
                        item.fieldValue != "0" &&
                        item.fieldName != "id" &&
                        item.fieldValue != "1"
                      ) {
                        fieldsList.push(item);
                      }
                    });
                    await detailSendCard(res.data[i].cardValue.groupId, {
                      title: self.$i18n.t("vue_label_newfile"),
                      recordId: res.data[i].cardValue.id,
                      type: "NORMAL_CARD",
                      body: fieldsList,
                      changer: localStorage.getItem("username"),
                    });
                  }, 500 * i);
                })(i);
              }
            }
            this.$message({
              showClose: true,
              type: "success",
              message: this.$i18n.t("vue_label_notice_add_success"),
            });
          } else {
            this.$message({
              showClose: true,
              type: "error",
              message: this.$i18n.t("vue_label_notice_add_fail"),
            });
          }
        });
        this.addWorkClose(true);
      },
      // 添加文件
      addFileSubmit(checkedFileList) {
        let ids = [];
        checkedFileList.forEach((file) => {
          ids.push(file.id);
        });
        // 文件上传个数超出时给提示
        this.formAttr.map((attr) => {
          if (attr.prop === this.fieldProp) {
            if (attr.length && ids.length > Number(attr.length)) {
              this.$message.warning(this.$i18n.t("vue_label_file_num_limit_tip"));
              this.$refs.addFile.buttonLoading = false;
            } else {
              attr.data = checkedFileList;
              this.$set(
                this.$refs.infoForm.formData,
                this.fieldProp,
                ids.toString()
              );
              this.addFileClose();
            }
          }
        });
      },
      // 关闭添加文件
      addFileClose() {
        this.addFileDialogVisible = false;
      },
      //获取标签
      getBrief() {
        this.bigTitle = [];
        this.tabName = "";
        this.tabStyle = "";
        this.editDiscountTiers = "";
        this.editQuoteline = "";
        detailApi.GetBrief({ id: this.id }).then((res) => {
          //如果父对象存在展示菜单中，则为父对象选项卡加选种样式，否则清除所有选中样式
          this.$Bus.$emit("change-detail-style", res.data.objid);
          if (res.result && res.returnCode === "1") {
            if (res.data) {
              this.objectApi = res.data.objapi;
              this.objectApiName = res.data.label;
              this.objId = res.data.objid;
              this.bigTitle = res.data.title;
              this.fieldValue = this.bigTitle[0].fieldValue;
              this.tabName = res.data.label;
              this.tabStyle = res.data.tabStyle ? res.data.tabStyle : "";
              this.$cookies.set("parentObjectApi", res.data.objapi, { sameSite: 'Strict' }); //存储父对象的ObjectApi、详情关联列表-实际工作清单新建使用
              this.buttonList = [];
              // 判断是否返回提交待审批
              let result = res.data.button.some((item) => {
                if (item.name === "Submit") {
                  return true;
                }
              });
              this.showSubmitBtn(result, res.data.approvalButtonMap);
              res.data.button.forEach((item) => {
                //改517问题（当出现自定义按钮从业务机会同步、同步到业务机会，先隐藏，用标准按钮功能）
                //('startsyncing '自定义按钮带空格，标准按钮不带，如果改，需要改四块地方)
                if (
                  item.name === "fromopportunity" ||
                  item.name === "startsyncing "
                ) {
                  delete item.iscustom;
                }
                // 后端进行了权限校验，前端直接展示
                // if (item.name == "Share" && this.isCanEditOwner) {
                if (item.name == "Share") {
                  //共享
                  this.buttonList.push(item);
                } else if (item.name === "Generate Invoice") {
                  if (res.data.objapi === "cloudccorder") {
                    detailApi.orderStatus({ orderid: this.id }).then((res) => {
                      if (
                        res.data[0].invoicestatus !== "已开具发票" &&
                        res.data[0].invoicestatus !== "All Invoiced"
                      ) {
                        this.buttonList.push(item);
                      }
                      this.showButton = this.buttonList.slice(0, 3);
                      this.moreButton = this.buttonList.slice(3);
                      this.buttonList.map((btnitem) => {
                        if (btnitem.name == "Edit") {
                          this.showEditButton = btnitem;
                        } else if (btnitem.name == "generate") {
                          this.showPdfButton = btnitem;
                        } else if (btnitem.name == "send") {
                          this.showMailButton = btnitem;
                        } else if (btnitem.name == "unlock") {
                          this.lockingButton = btnitem;
                        }
                      });
                    });
                  } else {
                    this.buttonList.push(item);
                  }
                } else if (
                  item.name === "unlock" ||
                  item.name === "Edit" ||
                  item.name === "Delete" ||
                  item.name === "PrintQuote" ||
                  item.name === "fromopportunity" ||
                  item.name === "startsyncing " ||
                  item.name === "Lead_Convers" ||
                  item.name === "Lead Convers" ||
                  item.name === "Clone" ||
                  item.name === "Return Pool" ||
                  item.name === "Save and Sync to QB" ||
                  item.name === "Print Asset Code" ||
                  item.name === "View Asset BOM" ||
                  item.name === "Copy Asset BOM" ||
                  item.name === "Delete Asset BOM" ||
                  item.name === "Transfer Asset BOM" ||
                  item.name === "Move Asset BOM" ||
                  item.name === "Product To Asset BOM" ||
                  item.name === "Order To Asset BOM" ||
                  item.name === "fabu" || //公告菜单下  标准按钮更改成 发布范围按钮 wzj  勿删勿删勿删
                  item.iscustom === "1" || // 自定义按钮
                  item.name === "Edit Draft" ||
                  item.name === "Publish" ||
                  item.name === "Change Record Type" ||
                  item.name === "Edit as Draft" ||
                  item.name === "Archive" ||
                  item.name === "Restore" ||
                  item.name === "Delete Draft" ||
                  item.name === "Synchronize Opportunity" ||
                  item.name === "Generate Order" ||
                  item.name === "Generate Contract" ||
                  item.name === "Payment" ||
                  item.name === "Create Contact" || //创建联系人
                  item.name === "Create Lead" || //创建潜在客户
                  item.name === "CreateWorkOrder" || //新建工作订单
                  item.name === "CreateContact" || //新建联系人
                  item.name === "CreateCase" || //新建个案
                  item.name === "CreateAccount" || //新建客户
                  item.name === "Request Pay Online" || //发起线上付款
                  item.name === "Send Invoice" || //发送发票
                  item.name === "send" || //模板标准发送功能
                  item.name === "generate" || //模板标准生成功能
                  // item.name === "Generate Invoice" //生成发票
                  item.name === "New Quote" || //新建报价单
                  item.name === "New Order" || //新建订单
                  item.name === "New Invoice" || //新建发票
                  item.name === "Generate Invoice" ||
                  item.name === "New Contract"
                ) {
                  // item.iscustom === "1"
                  // 解锁、编辑、删除、报价单打印、从业务机会同步、同步到业务机会、潜在客户转化、复制、自定义按钮、回退公海池
                  //查看资产BOM、复制资产BOM、删除资产BOM、转移资产BOM、迁移资产BOM、同步报价行至业务机会
                  this.buttonList.push(item);
                } else if (item.name === "Edit Quoteline") {
                  this.editQuoteline = item.label;
                } else if (item.name === "Edit Discount Tiers") {
                  this.editDiscountTiers = item.label;
                } else if (item.name === "Received") {
                  if (this.objectApi === "Invoices") {
                    // 获取是否展示确定收获按钮
                    detailApi
                      .getShippingstatus({ invoicesid: this.id })
                      .then((res) => {
                        if (res.returnCode === "10000") {
                          this.buttonList.push(item);
                          this.showButton = this.buttonList.slice(0, 3);
                          this.moreButton = this.buttonList.slice(3);
                          this.buttonList.map((btnitem) => {
                            if (btnitem.name == "Edit") {
                              this.showEditButton = btnitem;
                            } else if (btnitem.name == "generate") {
                              this.showPdfButton = btnitem;
                            } else if (btnitem.name == "send") {
                              this.showMailButton = btnitem;
                            } else if (btnitem.name == "unlock") {
                              this.lockingButton = btnitem;
                            }
                          });
                        } else {
                          return;
                        }
                      });
                  }
                }
              });
              if (this.objectApi === "Invoices") {
                if (this.isquick) {
                  this.buttonList = [
                    ...this.buttonList,
                    {
                      behavior: "self",
                      btn_type: "detailBtn",
                      event: "URL",
                      function_code: null,
                      id: "CCtoQB",
                      iscustom: null,
                      label: this.$i18n.t("front-sales-button-toQB"),
                      menubar: null,
                      name: "CCtoQB",
                      url: null,
                    },
                    {
                      behavior: "self",
                      btn_type: "detailBtn",
                      event: "URL",
                      function_code: null,
                      id: "QBtoCC",
                      iscustom: null,
                      label: this.$i18n.t("front-sales-button-fromQB"),
                      menubar: null,
                      name: "QBtoCC",
                      url: null,
                    },
                  ];
                  this.buttonList.forEach((res, index) => {
                    if (res.id == "adc2018saveqb39KmL") {
                      this.buttonList.splice(index, 1);
                    }
                  });
                }
              } else if (this.objectApi === "cloudccproceed" && this.isquick) {
                this.buttonList = [
                  ...this.buttonList,
                  {
                    behavior: "self",
                    btn_type: "detailBtn",
                    event: "URL",
                    function_code: null,
                    id: "CCtoQB",
                    iscustom: null,
                    label: this.$i18n.t("front-sales-button-toQB"),
                    menubar: null,
                    name: "CCtoQB",
                    url: null,
                  },
                  {
                    behavior: "self",
                    btn_type: "detailBtn",
                    event: "URL",
                    function_code: null,
                    id: "QBtoCC",
                    iscustom: null,
                    label: this.$i18n.t("front-sales-button-fromQB"),
                    menubar: null,
                    name: "QBtoCC",
                    url: null,
                  },
                ];
              } else if (this.objectApi === "cloudccorder" && this.isquick) {
                this.buttonList = [
                  ...this.buttonList,
                  {
                    behavior: "self",
                    btn_type: "detailBtn",
                    event: "URL",
                    function_code: null,
                    id: "CCtoQB",
                    iscustom: null,
                    label: this.$i18n.t("front-sales-button-toQB"),
                    menubar: null,
                    name: "CCtoQB",
                    url: null,
                  },
                ];
              }
              this.showButton = this.buttonList.slice(0, 3);
              this.buttonList.map((btnitem) => {
                if (btnitem.name == "Edit") {
                  this.showEditButton = btnitem;
                } else if (btnitem.name == "generate") {
                  this.showPdfButton = btnitem;
                } else if (btnitem.name == "send") {
                  this.showMailButton = btnitem;
                } else if (btnitem.name == "unlock") {
                  this.lockingButton = btnitem;
                }
              });
              this.moreButton = this.buttonList.slice(3);

              // 公告菜单下  标准按钮更改成 发布范围按钮 wzj     勿删勿删勿删
              if (this.objId === "Announcement20210520") {
                this.moreButton.forEach((item) => {
                  if (item.name === "Share") {
                    item.name = "fabu";
                    item.label = this.$i18n.t("label.ggReleaseRange.release"); //发布范围
                    item.id = "dfsgdgdfgdfg";
                  }
                });
                this.showButton.forEach((item) => {
                  if (item.name === "Share") {
                    item.name = "fabu";
                    item.label = this.$i18n.t("label.ggReleaseRange.release"); //发布范围
                    item.id = "dfsgdgdfgdfg";
                  }
                });
              }
              // 公告菜单下  标准按钮更改成 发布范围按钮 wzj

              if (
                res.data.objapi === "Account" ||
                res.data.objapi === "Contact"
              ) {
                this.getPartnerGetPartnerPermissions(res.data.objapi);
              }
              this.heightLight = JSON.parse(JSON.stringify(res.data.heigthLight));
              if (res.data.objapi == "CloudccKArticle") {
                this.typedisabled = this.heightLight[2].fieldValue;
              }
              //  获取主题值
              res.data.heigthLight.forEach((item) => {
                if (item.fieldName === "zhuti") {
                  this.theme = item.fieldValue;
                }
              });
              this.heightLight.forEach((item) => {
                this.$set(item, "isShowMenus", true);
              });
              //相关列表定位所需
              if (this.heightLight && this.heightLight.length > 0) {
                this.briefHeight = 60;
              } else {
                this.briefHeight = 0;
              }

              // 勿删
              this.$store.commit("changeBigTitle", this.fieldValue);
              this.$store.commit("changeTabName", this.tabName);

              this.$nextTick(() => {
                this.meunsFilter();
              });
              // 设置title标题
              if (this.fieldValue) {
                document.title = this.$setTitle(`${this.fieldValue}`);
              }
            }
            this.isSkeleton = false;
          } else {
            this.$message.error(res.returnInfo);
            // 让进度条隐藏
          }
          // 如果是伙伴账户 请求订单是否支付接口
          if (
            this.$store.state.userInfoObj.ispartner &&
            res.data.objapi == "cloudccorder"
          ) {
            this.isPayButton();
          }
        });
      },
      //获取相关列表对象信息
      //toText为false时只刷新相关列表菜单，不刷新每个相关列表对应数据
      GetRelationList(toText) {
        CommonObjApi.GetRelationList({ recordId: this.id }).then((res) => {
          if (res.result && res.returnCode === "1") {
            if (this.prefix === "021") {
              // 发票
              res.data.forEach((list) => {
                if (list.relationFieldId === "ffe20181109items04") {
                  this.relatedlistIds = list.relatedlistId;
                }
              });
            } else if (this.prefix === "011") {
              // 报价单
              res.data.forEach((list) => {
                if (list.relationFieldId === "ffe2011000063307X4r9") {
                  this.relatedlistIds = list.relatedlistId;
                }
              });
            } else if (this.prefix === "032") {
              // 订单
              res.data.forEach((list) => {
                if (list.relationFieldId === "ffe2021orderitem0056") {
                  this.relatedlistIds = list.relatedlistId;
                }
              });
            } else if (this.prefix === "092") {
              // 采购订单
              res.data.forEach((list) => {
                if (list.relationFieldId === "ffe2021puorderitem12") {
                  this.relatedlistIds = list.relatedlistId;
                }
              });
            } else if (this.prefix === "002") {
              // 业务机会
              res.data.forEach((list) => {
                if (list.relationFieldId === "ffe201300150058Vnr9C") {
                  this.relatedlistIds = list.relatedlistId;
                }
              });
            }
            let data = [];
            data = res.data.filter(
              // "备注"
              (item) =>
                item.relatedlistType != "activityHistory" &&
                item.relatedlistType != "openActivities" &&
                item.relationName !=
                  this.$i18n.t("label.weixin.group.description") &&
                item.relatedlistType != "campaign"
            );
            res.data.forEach((item) => {
              if (item.prefix == "pos" && item.relatedlistType == "poster") {
                this.postershow = true;
              } else if (
                item.prefix == "inv" &&
                item.relatedlistType == "invited"
              ) {
                this.invitshow = true;
              } else if (
                item.prefix == "015" &&
                item.relatedlistType == "campaign"
              ) {
                this.chnnelshow = true;
              } else if (
                item.prefix == "soc" &&
                item.relatedlistType == "social"
              ) {
                this.socialshow = true;
              }
            });
            if (toText) {
              data.forEach((val) => {
                this.$set(val, "titImg", val.tabStyle ? val.tabStyle : "");
                //设置每个相关列表loading
                this.$set(val, "loading", true);
              });
            }
            if (
              this.objectApi == "Invoices" ||
              this.objectApi == "cloudccorder" ||
              this.objectApi == "cloudccpurchaseorder" ||
              this.objectApi == "quote"
            ) {
              // 后台设置不显示微贴时，不显示动态
              if(this.isShowdynamicPublics){
                this.tabData = [
                  {
                    name: this.$i18n.t("label.ccchat.2018"),
                    code: "second",
                    relatedlistId:'second'
                  },
                ];
              }else{
                this.tabData =[]
              }

              res.data.map((item) => {
                let relationName = ""; //相关列表每个名称后拼接数量
                if (item.relationSize == 0) {
                  relationName = item.relationName;
                } else if (item.relationSize < 99) {
                  relationName =
                    item.relationName + "(" + item.relationSize + ")";
                } else {
                  relationName = item.relationName + "(99)";
                }
                // tab不显示报价单明细，发货单产品，订单产品，采购订单产品
                if (
                  (this.objectApi == "Invoices" &&
                    item.relationName != "发货单产品") || //c1059
                  (this.objectApi == "cloudccorder" &&
                    item.relationName != this.$i18n.t("lable.Order.product")) ||
                  (this.objectApi == "cloudccpurchaseorder" &&
                    item.relationName != "采购订单产品") || //c1060
                  (this.objectApi == "quote" &&
                    item.relationName != this.$i18n.t("label.quote.detail"))
                ) {
                  this.tabData.push({
                    name: relationName,
                    code: item.prefix,
                    relatedlistId:item.relatedlistId
                  });
                }
              });
              // 在不启用微贴的情况下默认选中第一个tab
              if(!this.isShowdynamicPublics){
                this.activeName=this.tabData&&this.tabData[0].relatedlistId
              }
            }

            this.list = [];
            // 产品 价格手册
            if (this.prefix === "009") {
              this.list.push({
                relationName: this.$i18n.t("lable.opppdt.selPbk.pricebook"),
                relatedlistId: "pricebook",
                relationSize: 0,
              });
            } else if (this.prefix === "006") {
              // 市场活动 影响业务机会
              // this.list.push({
              //   relationName: this.$i18n.t('vue_label_campaign_influencedopportunity'),'影响业务机会'
              //   relatedlistId: 'influenceBusinessOpportunities',
              //   relationSize: 0
              // })
            } else if (this.prefix === "001") {
              this.list.push({
                relationName: this.$i18n.t(
                  "vue_label_account_equityrelationship"
                ),
                relatedlistId: "stockRight",
                relationSize: 0,
              });
            }
            this.list = this.list.concat(data);
            // 市场云员工邀约和海报屏蔽
            if (this.objectApi === "campaign") {
              this.list = this.list.filter((type) => {
                return type.prefix !== "inv" && type.prefix !== "pos";
              });
            }
            this.$emit("getObjArr", this.list);
           this.$nextTick(() => {
              if (toText) {
                this.$refs.relationListObj &&
                  this.$refs.relationListObj.forEach((relationObj) => {
                    relationObj.init();
                  });
              }
            });

            this.isSkeleton = false;

            // 用户详情页添加关注列表
            if (this.isShowType == false) {
              // this.followers();
              // this.$bus.$on("followersTo", () => {
              //   this.followers(); //获取关注我的和我关注的详细信息
              // });
            }
          } else {
            this.$message.error(res.returnInfo);
          }
        });
      },
      init() {
        this.showEditButton = {};
        this.showPdfButton = {};
        this.showMailButton = {};
        this.isQuery = null;
        this.isShowType = false;
        this.fullscreenLoading = true;
        this.isFollow();
        sessionStorage.setItem("id", this.$route.params.id);
        // 获取记录查看权限，无权限时给提示，可返回上一级
        detailApi.getPermissionById({ id: this.id }).then((res) => {
          if (res.returnCode === "1" && res.result) {
            this.objectApi = res.data.objectApi;
            this.prefix = res.data.prefix;
            this.isEdit = res.data.isEdit;
            this.realObjId = res.data.objId;
            this.isLocked = res.data.isLocked;
            // this.isDelete = res.data.isDelete
            this.isCanEditOwner = res.data.canEditOwner;
            this.fullscreenLoading = false;
            if (this.objectApi == "cloudcccase") {
              this.$refs.listChild.getKnowledgePermissionById();
            }
            this.$nextTick(() => {});
            if (res.data.isQuery) {
              this.isQuery = true;
              this.isShowType = true;
              if (res.data.objId === "user") {
                this.isShowType = false;
                this.isQuery = true;
                this.dynamicType = true;
                // 有动态tab时默认显示动态，没有动态默认显示第一个
                if(this.isShowdynamicPublics){
                  this.activeName = 'second';
                }else{
                  this.activeName=this.tabData&&this.tabData[0].relatedlistId
                }
                this.$refs.dynamicPublics?.personDetailPage();
              } else {
                this.getBrief();
                this.generate("pdfview");
                this.getTagsByRecordId();
              }
              this.$refs.listChild.init();
            } else {
              this.isQuery = false;
            }
          } else {
            this.fullscreenLoading = false;
            this.$message.error(res.returnInfo);
          }
        });
      },
      //获取价格手册数据
      getPricebook(n) {
        this.pricebookArr = n;
      },
      // 报价单详情页新建发票按钮 带值方法
      quoteInitValue() {
        this.orignObj = "quote";
        // 报价单新建发票按钮 主录入带值问题
        this.getInitValue(
          [
            { oldField: "zongjia", newField: "total_amount" },
            {
              oldField: "totalcustomerdiscountamount",
              newField: "discountamount",
            },
            { oldField: "customerdiscount", newField: "discount" },
            { oldField: "tax", newField: "tax" },
            { oldField: "ratetype", newField: "ratetype" },
            { oldField: "adjustment", newField: "adjustment" },
            { oldField: "totalamount", newField: "total" },
            { oldField: "khmc", newField: "account_name" },
            { oldField: "lxrxm", newField: "contact_name" },
            { oldField: "ywjhmc", newField: "opportunity" },
            { oldField: "pricebook2id", newField: "pricebook2id" },
            { oldField: "currency", newField: "currency" },
            { oldField: "name", newField: "quote" },
          ],
          "quoteToInvoices"
        );
        this.getObjInfo({ id: "invoices" });
      },
      // 报价单详情页新建发票按钮提示弹窗取消方法
      cancelChange() {
        this.showQuoteIntro = false;
      },
      // 报价单详情页新建发票按钮提示弹窗确定方法
      confirmChange() {
        this.showQuoteIntro = false;
        this.quoteInitValue();
      },
      /**
       * 业务机会/报价单等数据转换 根据原数据获取新数据带值方法
       * @param {Object} arrayFiled 要转换的数据信息,包含原字段和新字段 字段apiname
       * @param {String} type 是否是业务机会转换为报价单/订单
       */
      getInitValue(arrayFiled, type) {
        this.opportunityInfo = {};
        let promiseList = [];
        promiseList.push(
          detailApi
            .masterRecordDetailInfo({
              objId: this.objId,
              recordId: this.id,
            })
            .then((res) => {
              localStorage.setItem(
                "masterRecordDetailInfoval",
                JSON.stringify(res.data[0])
              );
              return res;
            })
        );
        if (this.objectApi === "Opportunity") {
          // 业务机会产品从录入数据获取值
          promiseList.push(
            detailApi
              .relatedOpportunityPdtInfo({
                currency: "",
                opportunityId: this.id,
              })
              .then((res) => {
                res.data.forEach((item) => {
                  for (var key in item) {
                    if (key === "optionalsku") {
                      item.product2 = item.optionalsku;
                    } else if (key === "productname") {
                      item.product2ccname = item.productname;
                    }
                  }
                });
                return res;
              })
          );
        } else if (this.objectApi === "quote") {
          // 从报价单原记录获取数据
          let array = [];
          let objId = "";
          // 获取所有的报价单明细id
          // this.$refs.listChild.$refs.relationListObj.forEach((ele) => {
          //   if (ele.objectInfo.objName === "quotedetail") {
          //     objId = "2011000000062730EI25";
          //     ele.objectInfo.data.forEach((list) => {
          //       array.push(list.id);
          //     });
          //   }
          // });
          // 获取所有的报价行id
          // this.$refs.listChild.$refs.relationListObj.forEach((ele) => {
          //   if (ele.objectInfo.objName === "cloudccquoteline") {
          //     objId = "20210607thequoteline";
          //     ele.objectInfo.data.forEach((list) => {
          //       array.push(list.id);
          //     });
          //   }
          // });
          if (array.length > 0 && objId !== "") {
            // 报价单明细  报价行带值
            promiseList.push(
              detailApi
                .masterRecordDetailInfo({ objId, recordId: array.join(",") })
                .then((res) => {
                  let quoteArray = [];
                  res.data.forEach((dataList) => {
                    let quoteInfoobj = {};
                    for (var key in dataList) {
                      if (
                        objId === "2011000000062730EI25" &&
                        (key === "chanpin" ||
                          key === "chanpinccname" ||
                          key === "selectdiscount" ||
                          key === "cpdm" ||
                          key === "xsjg" ||
                          key === "shuliang" ||
                          key === "xiaoji" ||
                          key === "discountamount" ||
                          key === "zhekou" ||
                          key === "tax" ||
                          key === "ratetype" ||
                          key === "zongjia")
                      ) {
                        quoteInfoobj[key] = dataList[key];
                      } else if (
                        objId === "20210607thequoteline" &&
                        (key === "product" ||
                          key === "productccname" ||
                          key === "originalprice" ||
                          key === "quantity" ||
                          key === "additionaldiscountamount" ||
                          key === "discount" ||
                          key === "productcode")
                      ) {
                        quoteInfoobj[key] = dataList[key];
                      }
                    }
                    quoteArray.push(quoteInfoobj);
                  });
                  return quoteArray;
                })
            );
          }
        }
        // 业务机会新建报价单  新建发票需要获取当前记录客户的关联信息
        if (type === "specialQuote" || type === "specialInvoice") {
          promiseList.push(
            detailApi
              .getRelatedAccountInfo({
                id: this.id,
                keyword: "Opportunity",
              })
              .then((result) => {
                return result;
              })
          );
        }
        Promise.all(promiseList).then((allres) => {
          allres.forEach((list, index) => {
            if (index === 0) {
              let infoObj = list.data[0];
              arrayFiled.forEach((name) => {
                for (var key in infoObj) {
                  if (key === name.oldField && name.oldField !== "name") {
                    if (infoObj[`${key}ccname`]) {
                      this.opportunityInfo[name.newField] = [
                        { id: infoObj[key], value: infoObj[`${key}ccname`] },
                      ];
                    } else {
                      this.opportunityInfo[name.newField] = [
                        { id: infoObj[key] },
                      ];
                    }
                  } else if (key === name.oldField && name.oldField === "name") {
                    this.opportunityInfo[name.newField] = [
                      { id: this.id, value: infoObj.name },
                    ];
                  }
                  if (key === "createbyid") {
                    let str = infoObj.createdate.split(" ")[0];
                    this.opportunityInfo.createbyid = [{ id: str }];
                  }
                }
              });
              if (type === "specialOrder") {
                let arrayInfo = this.opportunityInfo.pricebook2id;
                if (arrayInfo && arrayInfo[0].id && arrayInfo[0].id !== "") {
                  // 获取已启用的价格手册
                  detailApi.getEnablePriceBook().then((res) => {
                    let isOnPriceBook = false;
                    res.data.forEach((list) => {
                      if (arrayInfo[0].id === list.id) {
                        isOnPriceBook = true;
                      }
                    });
                    if (isOnPriceBook) {
                      this.getObjInfo({ id: "20210525cloudccorder" });
                    } else {
                      this.$message.warning(this.$i18n.t("c788"));
                    }
                  });
                }
              }
            } else if (index === 2) {
              let infoObj = list.data[0];
              if (type === "specialQuote") {
                if (infoObj.contactId && infoObj.contactId !== "") {
                  this.opportunityInfo.lxrxm = [
                    { id: infoObj.contactId, value: infoObj.contactName },
                  ];
                }
                // 传真
                this.opportunityInfo.dianhua = [{ id: infoObj.dianhua }];
                // 电话
                this.opportunityInfo.chuanzhen = [{ id: infoObj.fax }];
              } else if (type === "specialInvoice") {
                if (infoObj.contactId && infoObj.contactId !== "") {
                  this.opportunityInfo.contact_name = [
                    { id: infoObj.contactId, value: infoObj.contactName },
                  ];
                }
              }
            } else if (index === 1) {
              this.opportunitySecInfo = list.data ? list.data : list;
            }
          });
        });
      },
      async getObjInfo(params) {
        let res = await detailApi.getObjectGetObjectInfo(params);
        this.opportunityNameRecive = res.data.labelname;
        this.opportunityPrefix = res.data.prefix;
        this.opportunityApi = res.data.objectapi;
        this.$refs.opportunityCeateEditObj.objId = res.data.id;
        this.$nextTick(() => {
          this.$refs.opportunityCeateEditObj.add();
        });
      },
      //暂无文件列表时生成
      opencreatedquo() {
        this.saveTemplatePdf();
      },
      // 发送邮件时没有文件把当前记录类型和模板保存到文件然后显示发送邮件弹框
      saveTemplatePdf(){
      // 保存文件
        this.buttonLoading = true;
        let params = {
          id: this.templateId,
          recordId: this.id,
          objid: this.objId,
          fileName: this.templatename,
          type: this.templateType,
        };
        detailApi.saveTemplatePdf(params).then((res) => {
          if (res.result) {
            let obj = {
              fileContentId: res.data.fileId,
              name: res.data.fileFileName,
            };
            this.closeQuotaionDialog(true, [obj]);
          }
        });
      },
      //获取对象详细信息
      getDetail() {
        let params = {
          id: this.id,
          objectApi: "",
          operation: "DETAIL",
          recordType: "",
        };
        detailApi.getDetail(params).then((res) => {
          this.recordType = res?.data?.recordType;
        });
      },
      //标准模板生成功能
      generate(flag) {
        // flag=dialog是点击操作按钮生成PDF模板
        // flag为空是PDF视图详情页中的模板
        if (flag != "pdfview") {
          this.emailIsOperation = true;
        }
        //模板标准生成功能
        this.printTitle = this.$i18n.t("c111", {
          name: this.eventTap || this.tabName,
        });
        this.printType = "quotation";

        detailApi.getObjTemplateList({ objId: this.objId }).then((res) => {
          if (res.result) {
            let flg = true;
            res.data.forEach((element) => {
              if (element.isenable === "1") {
                flg = false;
              }
            });
            if (flg) {
              this.$message.error(
                this.$i18n.t("c112", { name: this.eventTap || this.tabName })
              );
            } else {
              if (this.recordType) {
                this.templateList = res.data.filter((item) => {
                  return item.recordtype == this.recordType;
                });
              } else {
                this.templateList = res.data;
              }
              // 模板时间排序
              this.templateList.sort((a, b) => {
                let dateOne = new Date(a.lastmodifydate);
                let dateTow = new Date(b.lastmodifydate);
                if (dateOne === dateTow) {
                  return 0;
                } else if (dateOne < dateTow) {
                  return 1;
                } else {
                  return -1;
                }
              });
              this.templateList.sort((a, b) => {
                if (b.isenable === "0") {
                  return -1;
                } else {
                  return 1;
                }
              });
              if (flag === "pdfview") {
                if (this.templateList && this.templateList.length > 0) {
                  // 如果有记录类型，取相同记录类型的模板的第一条数据
                  // 如果没有记录类型，取isstd==1的标准模板
                  if (this.recordType) {
                    this.templateId = this.templateList[0].templateid;
                    this.templatename = this.templateList[0].templatename;
                    this.subid = this.templateList[0].subid;
                    this.templateType = this.templateList[0].type;
                    this.getTemplateInfo();
                  } else {
                    this.templateList.map((item) => {
                      if (item.isstd == "1") {
                        this.templateId = item.templateid;
                        this.templatename = item.templatename;
                        this.subid = item.subid;
                        this.templateType = item.type;
                        this.getTemplateInfo();
                      }
                    });
                  }
                } else {
                  this.pdfTemplateLoad = false;
                }
              } else {
                this.invoiceTemplateDialogVisible = true;
                this.closeEmailDialogFlg = 1;
                this.$refs.invoiceTemplate.init(
                  this.fieldValue,
                  this.templateList[0].templateid
                );
              }
            }
          } else {
            this.$message({
              showClose: true,
              type: "error",
              message: res.returnInfo,
            });
          }
        });
      },
      // 确认改变发票的产品运输状态
      updateStatus() {
        this.invoicesDialogVisible = false;
        detailApi.updateShippingstatus({ invoicesid: this.id }).then((res) => {
          if (res.returnCode === "10000") {
            this.refreshAll();
            this.$message.success(this.$i18n.t("c66"));
          }
        });
      },
      //关闭提交待审批按钮loading
      closeLoading() {
        this.submitStatus = false;
      },
      //QB是否启用
      startQB() {
        detailApi.quickbookStart().then((res) => {
          if (res.result && res.data && res.data == "1") {
            this.isquick = true;
          } else {
            this.isquick = false;
          }
        });
      },
      // 文章分类判断是否为主版本
      getstate() {
        // this.is_master_version = this.$refs.listChild.is_master_version;
      },
      // PDF视图预览
      closeSelectTemplateDialog(flg, data) {
        this.selectTemplateDialogVisible = false;
        if (flg) {
          this.$refs.createdQuotationDialog.init(data);
          this.quotationDialogVisible = true;
        }
      },
      //关闭发票选择模板弹框
      closeinvoiceTemplateDialog(flg, data) {
        this.invoiceTemplateDialogVisible = false;
        if (flg) {
          this.$refs.createdQuotationDialog.init(data);
          this.templateId = data.templateId;
          this.templatename = data.templatename;
          this.subid = data.subid;
          this.quotationDialogVisible = true;
        }
      },
      // 关闭生成报价单弹窗
      closeQuotaionDialog(val, item) {
        this.quotationDialogVisible = false;
        if (item) {
          this.dialogVisibleEmail = true;
          this.enclosurearr = item;
        } else {
          this.dialogVisibleEmail = false;
          this.refreshUpload();
        }
      },
      //关闭选择报价单弹框
      closedselectquotation(val, selectval) {
        if(val===false){
          this.dialogTableVisible = false;
        }else{
          // 当发送邮件前有多个文件，显示选择文件弹框，只有一个文件时直接携带模板发送邮件
          if(this.$refs.selectquotation&&this.$refs.selectquotation.List.length>1){
            this.dialogTableVisible = true;
          }else if(this.$refs.selectquotation&&this.$refs.selectquotation.List.length==1){
            this.enclosurearr = this.$refs.selectquotation.List;
            this.dialogTableVisible = false;
            this.dialogVisibleEmail = true;
            return ;
          }
        }
        if (selectval) {
          this.enclosurearr = selectval;
          this.dialogVisibleEmail = true;
        } else {
          this.dialogVisibleEmail = false;
        }
      },
      //关闭邮件弹框
      closeEmailDialog(flg) {
        if (!flg && this.closeEmailDialogFlg === 1) {
          this.dialogVisibleEmail = false;
          this.quotationDialogVisible = true;
        } else if (!flg && this.closeEmailDialogFlg === 2) {
          this.dialogVisibleEmail = false;
          this.enclosurearr = [];
          this.dialogTableVisible = true;
          this.$refs.selectquotation.getFilesdata();
        } else if (flg && this.closeEmailDialogFlg === 1) {
          this.dialogVisibleEmail = false;
          this.enclosurearr = [];
          this.refreshUpload();
        } else {
          this.dialogVisibleEmail = false;
          this.enclosurearr = [];
        }
      },
      // 获取CCChat后台设置信息
      async getSetupInfo() {
        let res = await detailApi.getSetupInfo();
        this.isShowdynamicPublics = res.data.isEnable;
        if (res.data.isEnable == "true") {
          // 在可以显示微贴的情况下默认选中微贴
          this.activeName='second'
          this.$refs.dynamicPublics?.second();
        }
      },
      goToPay() {
        let totalPrice = "";
        this.heightLight.map((item) => {
          if (item.fieldName == "totalamount") {
            totalPrice = item.fieldValue.split(" ")[1];
          }
        });
        totalPrice = totalPrice.replace(",", "");
        this.$router.push({
          path: `/orderPay/${totalPrice}/${this.id}`,
        });
      },
      // 是否展示付款按钮
      async isPayButton() {
        let result = await detailApi.getPaymentStatus({ orderid: this.id });
        let res = await detailApi.getPayMethod({});
        if (
          (result.data.paymentstatus == "未回款" ||
            result.data.paymentstatus == "部分回款" ||
            result.data.paymentstatus == "Unpaid" ||
            result.data.paymentstatus == "Partially Paid") &&
          (res.data.isOnlinePayment === "true" ||
            res.data.avaliable !== "" ||
            res.data.isUsingPartnerFund == "true")
        ) {
          this.showPayButton = true;
        } else {
          this.showPayButton = false;
        }
      },
      // 如果是资产，获取用户资产操作权限
      getAssetBomPermission() {
        detailApi.isOpenAsset({}).then((res) => {
          if (res.result) {
            this.isOpenAssetBom = res.data || false;
          }
        });
      },
      // 关闭公告发布范围弹窗
      closeGgRecord(ggdata) {
        this.isShowggRecord = ggdata;
      },
      // 查看翻译
      findArticleIdTranslate() {
        let params = {
          articleId: this.id,
        };
        detailApi.findArticleIdTranslate(params).then((res) => {
          if (res.data !== null && res.data.langevalue !== null) {
            this.langevalue = res.data.langevalue;
            this.langeoptions = res.data.langeoptions;
            this.langeoptionslength = res.data.langeoptions.length;
          }
        });
      },
      // 点击跳转新页面
      jumpNew(newHref) {
        window.open(newHref);
        // location.replace(newHref)
      },
      // 左右布局切换
      switchLayout() {
        this.detailLayoutMode =
          this.detailLayoutMode === "mode1" ? "mode2" : "mode1";
        VueCookies.set("detailLayoutMode", this.detailLayoutMode, { sameSite: 'Strict' });
        this.$nextTick(() => {
          // this.$refs.listChild && this.$refs.listChild.setRelationNameWidth();
        });
      },
      //根据视口宽度计算简介内容展示
      meunsFilter() {
        let itemWidth = 0;
        let intLen = document.getElementsByClassName("introduce_content");
        for (var i = 0; i <= intLen.length; i++) {
          if (intLen[i]) {
            itemWidth = intLen[i].offsetLeft + 300;
          }
          if (itemWidth >= this.screenWidth) {
            if (this.heightLight[i]) {
              this.heightLight[i].isShowMenus = false;
            }
          } else {
            if (this.heightLight[i]) {
              this.heightLight[i].isShowMenus = true;
            }
          }
        }
      },
      // 获取从子组件list传过来的详情页跳转用户名
      personsNameMethod(name) {
        this.userName = name;
        document.title = this.$setTitle(`${name}`);
      },
      // 刷新整个页面
      refreshAll() {
        this.init();
        document.getElementById("contentMainBox").scrollTop = 0;
        this.$nextTick(() => {
          this.$refs.uponDetailHistory.getApprovalPreview();
          if (
            this.isOpenService === "true" &&
            (this.objectApi === "WorkOrder" || this.objectApi === "cloudcccase")
          ) {
            this.$refs.importantEvent.renderPages();
          }
        });
      },
      //刷新文件列表
      refreshUpload() {
        this.$nextTick(() => {
          // this.$refs.listChild.init();
          this.$refs.uponDetailHistory.getApprovalPreview();
          if (
            this.isOpenService === "true" &&
            (this.objectApi === "WorkOrder" || this.objectApi === "cloudcccase")
          ) {
            this.$refs.importantEvent.renderPages();
          }
          // this.$refs.listChild.menuBtn({
          //   relatedlistId: "aeethequote2747f3905",
          // });
        });
      },
      joinGroup() {
        if (this.qunceType == "exist") {
          this.$Bus.$emit("create-strategy-groupexist", this.qunceData);
          this.isShowTag = false;
        } else if (this.qunceType == "notexist") {
          if (Object.keys(this.groupStrategyOwnerid).length > 0) {
            if (this.groupStrategyOwnerid.value == "") {
              this.$message({
                showClose: true,
                type: "warning",
                message: this.$i18n.t(
                  "vue_label_commonobjects_detail_a_group_strategy"
                ), //该条数据缺少所有人,无法创建群策
              });
              return false;
            }
            if (this.groupStrategyOwnerid.id == localStorage.getItem("myid")) {
              this.qunceData.groupIdArr = [
                JSON.parse(localStorage.getItem("userInfo")).userId,
              ];
              this.qunceData.groupName =
                this.bigTitle && this.bigTitle[0].fieldValue;
              this.qunceData.groupNames = localStorage.getItem("username");
              this.qunceData.userIdArr = localStorage.getItem("myid");
              this.$Bus.$emit(
                "create-strategy-allnotexist",
                this.qunceData,
                this.id
              );
            } else {
              this.qunceData.groupIdArr = [
                JSON.parse(localStorage.getItem("userInfo")).userId,
              ];
              this.qunceData.groupName =
                this.bigTitle && this.bigTitle[0].fieldValue;
              this.qunceData.userIdArr = [localStorage.getItem("myid")];
              this.getUserInfor(this.groupStrategyOwnerid.id, this.qunceData);
            }
          } else {
            this.$message({
              showClose: true,
              type: "warning",
              message: this.$i18n.t(
                "vue_label_commonobjects_detail_customer_details"
              ), //正在加载客户详情
            });
            return false;
          }
          this.isShowTag = false;
        }
      },
      cancelDia() {
        this.isShowTag = false;
      },
      //判断业务机会或客户是否创建了群策
      async getIsHasGroup() {
        let result = await job.judgeIsHasStrategy(this.id);
        if (
          result.result == true &&
          result.data &&
          result.data.strategyGroupId &&
          result.data.strategyGroupId != ""
        ) {
          this.detailGroupId = result.data.strategyGroupId;
        }
      },
      //群策获取客户信息
      judgeIsHas: debounce(
        async function () {
          let result = await job.judgeIsHasStrategy(this.id);
          if (result.result == true && result.data) {
            let item = {};
            item.strategyType = result.data.objid;
            if (
              result.data.strategyGroupId &&
              result.data.strategyGroupId != ""
            ) {
              let groupMessage = await job.getgroupmessage(
                result.data.strategyGroupId
              );
              if (groupMessage.result == true && groupMessage.data) {
                item.name = groupMessage.data.name;
                item.id = result.data.strategyGroupId;
              }
            } //存在群策，并且已经加入
            if (
              result.data.existStrategyGroup == "true" &&
              result.data.joinStrategyGroup == "true"
            ) {
              this.$Bus.$emit("create-strategy-allexist", item);
            } //存在群策，但没有加入
            if (
              result.data.existStrategyGroup == "true" &&
              result.data.joinStrategyGroup == "false"
            ) {
              //打开群策对应的群
              this.isShowTag = true;
              this.qunceType = "exist";
              this.qunceData = item;
            } //不存在群策
            if (
              result.data.existStrategyGroup == "false" &&
              result.data.joinStrategyGroup == "false"
            ) {
              this.isShowTag = true;
              this.qunceType = "notexist";
              this.qunceData = item;
            }
          }
        },
        3000,
        { leading: true, trailing: false }
      ),
      async getUserInfor(id, item) {
        let userId = await job.getUserInformation(id);
        if (userId.result == true && userId.data) {
          item.groupIdArr.push(userId.data[0].imUserId);
          item.groupNames = userId.data[0].name;
          item.userIdArr.push(userId.data[0].id);
          item.userIdArr = item.userIdArr.join();
          this.$Bus.$emit("create-strategy-allnotexist", item, this.id);
        }
      },
      // 鼠标悬浮
      mouseover() {
        // 当已关注后鼠标悬浮变成取消关注
        if (this.follow === this.$i18n.t("label.chatter.followed")) {
          this.follow = this.$i18n.t("vue_label_CCChat_unfollow");
        }
      },
      // 当按钮为取消关注时鼠标离开变成已关注
      mouseout() {
        if (this.follow === this.$i18n.t("vue_label_CCChat_unfollow")) {
          this.follow = this.$i18n.t("label.chatter.followed");
        }
      },
      // 判断是否关注
      async isFollow() {
        let params = {
          id: this.id,
        };
        let res = await detailApi.isFollow(params);
        if (res.data.isFollow) {
          // 已关注
          this.follow = this.$i18n.t("label.chatter.followed");
        } else {
          //关注
          this.follow = this.$i18n.t("label.chatter.follow");
        }
      },
      // 点击关注 取消关注
      async setFollow(val) {
        let params = {};
        if (val == this.$i18n.t("label.chatter.follow")) {
          params = {
            id: this.id,
            followType: "record",
            followStatus: "1", //关注
          };
        } else if (val == this.$i18n.t("vue_label_CCChat_unfollow")) {
          params = {
            id: this.id,
            followType: "record",
            followStatus: "0", //取消关注
          };
        }

        let res = await detailApi.setFollow(params);
        if (res.result) {
          if (val == this.$i18n.t("label.chatter.follow")) {
            // 按钮为关注时点击后变成已关注
            this.follow = this.$i18n.t("label.chatter.followed");
          } else if (val == this.$i18n.t("vue_label_CCChat_unfollow")) {
            // 按钮为取消关注时点击后变成关注
            this.follow = this.$i18n.t("label.chatter.follow");
          }
        }
      },
      //关闭添加标签
      closeAddBtn(n) {
        this.customFlag = n;
      },
      // 获取新添加的标签
      determineFun() {
        this.customFlag = false;
        this.getTagsByRecordId();
      },
      getDelLabelArr(msg) {
        this.labelArr = msg;
        // this.$refs.listChild.loadingDetail = true;
        // this.$refs.listChild.operation = "DETAIL";
        // this.$refs.listChild.getFormAttr();
      },

      next() {
        if (this.active++ > 2) this.active = 0;
      },
      getTagsByRecordId() {
        detailApi
          .GetTagsByRecordId({
            recordId: this.id,
          })
          .then((res) => {
            this.labelArr = res.data;
          });
      },
      resetObjectInfo(data) {
        this.objId = data.objId ? data.objId : this.objId;
        // this.objectApi = data.objectApi;
        this.prefix = data.prefix ? data.prefix : this.prefix;
        if (data.objectApi === "Account") {
          this.isCustomer = true;
          //客户层级结构
          this.content = this.$i18n.t(
            "vue_label_commonobjects_detail_customer_hierarchy"
          );
        } else if (data.objectApi === "Contact") {
          this.isCustomer = true;
          // 联系人层级结构
          this.content = this.$i18n.t(
            "vue_label_commonobjects_detail_contact_hierarchy"
          );
        } else if (data.objectApi === "campaign") {
          this.isCustomer = true;
          //"市场层级结构";
          this.content = this.$i18n.t(
            "vue_label_commonobjects_detail_market_hierarchy"
          );
        } else if (data.objectApi === "Asset") {
          this.isCustomer = true;
          // 资产层级结构
          this.content = this.$i18n.t("front-asset-module-v1-view-asset-bom");
          this.isShowAssets = true; //显示按钮查看资产BOM
        } else {
          this.isCustomer = false;
        }
      },
      getHistorySubmit() {
        let obj = {
          id: "",
          label: this.$i18n.t("label.submitforapproval"),
          hisId: "0",
        };
        if (this.$route.query.saveandapproval === "true") {
          this.historyDeal(obj);
        }
      },
      // 展示提交按钮
      showSubmitBtn(flag, approvalStatus) {
        if (flag) {
          let tempObj = {};
          if (
            approvalStatus.isApproval ||
            approvalStatus.isReassign ||
            approvalStatus.isRecall
          ) {
            if (approvalStatus.isApproval) {
              tempObj.showApproval = true;
            }
            if (approvalStatus.isReassign) {
              tempObj.showReassign = true;
            }
            if (approvalStatus.isRecall) {
              tempObj.showRecall = true;
            }
          } else {
            tempObj.showSubmit = true;
          }

          this.showHeaderHistoryBtn(tempObj);
        } else {
          let tempObj = {};
          if (approvalStatus.isApproval) {
            tempObj.showApproval = true;
          }
          if (approvalStatus.isReassign) {
            tempObj.showReassign = true;
          }
          if (approvalStatus.isRecall) {
            tempObj.showRecall = true;
          }

          this.showHeaderHistoryBtn(tempObj);
        }
      },
      showHeaderHistoryBtn(data) {
        this.showHistoryBtn = [];
        this.hideHistoryBtn = [];
        let btnArr = [];
        if (data.showSubmit) {
          btnArr.push({
            id: "",
            label: this.$i18n.t("label.submitforapproval"),
            hisId: "0",
          }); //"提交待审批"
        }
        if (data.showApproval) {
          btnArr.push({
            id: "",
            label: this.$i18n.t("label.approveorrefuse"),
            hisId: "2",
          }); //进行审批
        }
        if (data.showReassign) {
          btnArr.push({
            id: "",
            label: this.$i18n.t("label.redistribution"),
            hisId: "3",
          }); //重新分配
        }
        if (data.showRecall) {
          btnArr.push({
            id: "",
            label: this.$i18n.t("label.manageAll.sta4"),
            hisId: "1",
          }); //调回批准请求
        }
        let hasShenpi = false;
        btnArr.forEach((item) => {
          if (item.hisId === "2") {
            hasShenpi = true;
          }
        });
        if (btnArr.length < 2) {
          this.showHistoryBtn = btnArr;
          this.hideHistoryBtn = [];
        } else {
          if (hasShenpi) {
            btnArr.forEach((item) => {
              if (item.hisId === "2") {
                this.showHistoryBtn.push(item);
              } else {
                this.hideHistoryBtn.push(item);
              }
            });
          } else {
            this.showHistoryBtn = btnArr.slice(0, 1);
            this.hideHistoryBtn = btnArr.splice(1);
          }
        }
      },
      // 审批历史右上角展示的按钮
      getHistoryBtn(arr) {
        this.showHistoryBtn = [];
        this.hideHistoryBtn = [];
        let hasShenpi = false;
        arr.forEach((item) => {
          if (item.hisId === "2") {
            hasShenpi = true;
          }
        });
        if (arr.length < 2) {
          this.showHistoryBtn = arr;
          this.hideHistoryBtn = [];
        } else {
          if (hasShenpi) {
            arr.forEach((item) => {
              if (item.hisId === "2") {
                this.showHistoryBtn.push(item);
              } else {
                this.hideHistoryBtn.push(item);
              }
            });
          } else {
            this.showHistoryBtn = arr.slice(0, 1);
            this.hideHistoryBtn = arr.splice(1);
          }
        }
      },
      historyDeal(item) {
        if (item.hisId == 0) {
          if (this.underDetailSubmitStatus) {
            this.underDetailSubmitStatus = false;
            //loading启用
            this.submitStatus = true;
            this.$refs.uponDetailHistory.parentClickHistory(
              item.hisId,
              this.detailGroupId,
              this.id
            );
          }
        } else {
          this.$refs.uponDetailHistory.parentClickHistory(
            item.hisId,
            this.detailGroupId,
            this.id
          );
        }
      },
      // 刷新知识文章详情页面
      refreshall() {
        this.init();
        document.getElementById("contentMainBox").scrollTop = 0;
        this.$nextTick(() => {
          this.$refs.uponDetailHistory.getApprovalPreview();
        });
      },
      //知识库发布关闭弹窗
      releasehandleClose() {
        this.releasedialogVisible = false;
      },
      // 解决个案下文章列表作为草稿编辑
      copy(val) {
        this.dialogNewAttr.id = val;
        this.listObjectApi = "CloudccKArticle";
        this.$nextTick(() => {
          this.$refs.createEditObj.objId = "cloudcc_k_article";
          this.$refs.createEditObj.copy(this.dialogNewAttr.id);
        });
      },
      /**
       * recordOperationBtn:右上角按钮点击方法,添加防抖
       * @param {Object} item
       * @param {String} flag:如果flag==='noclick',接口加loading
       */
      recordOperationBtn: debounce(
        function (item, flag) {
          // 自定义按钮
          if (
            item.iscustom === "1" &&
            item.name !== "Lead_Convers" &&
            item.name !== "Lead Convers"
          ) {
            // lightning版本javescript类型自定义按钮 调用特定方法执行服务
            if (item.event === "lightning") {
              // 替换id
              if (item.function_code.indexOf("{!id}") !== -1) {
                item.function_code = item.function_code.replace("{!id}", this.id);
              }
              let param = JSON.parse(item.function_code);
              param.id = this.id;
              this.noClick = flag === "noclick";
              detailApi.openCall(param).then((res) => {
                this.noClick = false;
                if (res.data && res.data.action) {
                  // 刷新页面
                  if (res.data.action === "refresh") {
                    this.refreshAll();
                  } else if (res.data.action === "redirect") {
                    // 重定向 需判断当前窗口打开还是新窗口打开
                    if (res.data.mode === "new") {
                      window.open(res.data.url);
                    } else if (res.data.mode === "now") {
                      window.location.href = res.data.url;
                    }
                  } else if (res.data.action === "alert") {
                    // 不同类型的消息提示   wj
                    this.$message({
                      message: res.data.message,
                      type: res.data.messageType || "info",
                    });
                  }
                  //先弹窗，在当前页面跳转
                  else if (res.data.action === "alert_refresh") {
                    // 不同类型的消息提示   wj
                    this.$message({
                      message: res.data.message,
                      type: res.data.messageType || "info",
                    });
                    setTimeout(() => {
                      this.refreshAll();
                    }, 1000);
                  }
                  //先弹窗,在新标签页跳转
                  else if (res.data.action === "alert_redirect") {
                    // 不同类型的消息提示   wj
                    this.$message({
                      message: res.data.message,
                      type: res.data.messageType || "info",
                    });
                    setTimeout(() => {
                      if (res.data.mode === "new") {
                        window.open(res.data.url);
                      } else if (res.data.mode === "now") {
                        if (`/${window.location.hash}` === res.data.url) {
                          this.refreshAll();
                        } else {
                          window.location.href = res.data.url;
                        }
                      }
                    }, 1000);
                  }
                }
              });
            }
            if (item.event === "lightning-url") {
              // 替换id
              if (item.function_code.indexOf("{!id}") !== -1) {
                item.function_code = item.function_code.replace("{!id}", this.id);
              }
              // 当前窗口打开
              if (item.behavior === "self") {
                // window.location.href = item.function_code
                if (item.function_code.substring(0, 7) == "cloudcc") {
                  let searchUrl = item.function_code.split("?")[1];
                  let id = item.function_code
                    .split("?")[1]
                    .split("&")[0]
                    .split("=")[1];
                  let page = item.function_code
                    .split("?")[1]
                    .split("&")[1]
                    .split("=")[1];
                  let routeData = this.$router.resolve({
                    path: "/injectionComponent1",
                    query: { id: id, page: page },
                  });
                  if (item.function_code.indexOf("target") !== -1) {
                    //在本页面打开一个新的窗口
                    let paramsObj = this.$qs.parse(searchUrl); // 获取从？开始的url部分
                    if (paramsObj.target.indexOf("dialog") !== -1) {
                      let obj = {
                        title: paramsObj.dialogTitle || "详情",
                        width: paramsObj.dialogWidth || "50%",
                        height: paramsObj.dialogHeight || "",
                        pageApi: page,
                      };
                      this.$bus.$emit("openDialog", obj);
                    } else if (paramsObj.target.indexOf("window") !== -1) {
                      window.open(
                        routeData.href,
                        "newWindow",
                        "width=800,height=600,left=400,top=100,menubar=0,scrollbars=1, resizable=1,status=1,titlebar=0,toolbar=0,location=1"
                      );
                    }
                  } else {
                    // 覆盖
                    window.open(routeData.href, "_self");
                  }
                } else {
                  window.location.href = item.function_code;
                }
              } else {
                // 新窗口打开
                // window.open(item.function_code);
                if (item.function_code.substring(0, 7) == "cloudcc") {
                  let id = item.function_code
                    .split("?")[1]
                    .split("&")[0]
                    .split("=")[1];
                  let page = item.function_code
                    .split("?")[1]
                    .split("&")[1]
                    .split("=")[1];
                  let routeData = this.$router.resolve({
                    path: "/injectionComponent1",
                    query: { id: id, page: page },
                  });
                  window.open(routeData.href, "_blank");
                } else {
                  window.open(item.function_code);
                }
              }
            } else if (
              (item.event === "JavaScript" &&
                item.id !== "adc201300153022ukrN5" &&
                item.id !== "adc201596326104TADlh") ||
              item.event === "URL"
            ) {
              // JavaScript和URL类型暂时打开接口返回新页面处理
              // item.url != null && window.open(item.url)
              this.$message.warning(this.$i18n.t("lable.Please.switch"));
            }
          } else if (item.name === "unlock") {
            // 解除锁定
            this.dialogVisible = true;
            this.dialogAttr.title = this.$i18n.t("label.unlock");
            this.dialogAttr.content = this.$i18n.t(
              "vue_label_commonobjects_detail_unlockconfirm"
            ); //'确认解除锁定？'
          } else if (item.name === "Delete") {
            // 删除
            if (this.$store.state.userInfoObj.profileId === "aaa000001") {
              this.dialogVisible = true;
              this.dialogAttr.title = this.$i18n.t(
                "component_setup_tabs_label_delete"
              );

              if (this.objId === "cloudcc_project") {
                //删除该项目，将删除项目和项目关联的所有信息，且数据无法恢复，是否确认删除？
                this.dialogAttr.content = this.$i18n.t(
                  "label.projectManagement.delete.item"
                );
              } else if (this.objId === "cloudccMilestone") {
                //删除该里程碑，将删除里程碑和里程碑关联的所有信息，且数据无法恢复，是否确认删除？
                this.dialogAttr.content = this.$i18n.t(
                  "label.projectManagement.delete.milestone"
                );
              } else if (this.objId === "cloudcc_problem") {
                //删除该问题，将删除问题和问题关联的所有信息，且数据无法恢复，是否确认删除？
                this.dialogAttr.content = this.$i18n.t(
                  "label.projectManagement.delete.question"
                );
              } else if (this.objId === "cloudccTask") {
                //删除该任务，将删除任务和任务关联的所有信息，且数据无法恢复，是否确认删除？
                this.dialogAttr.content = this.$i18n.t(
                  "label.projectManagement.delete.task"
                );
              } else if (this.objId === "cloudccSubtask") {
                // 删除该子任务，将删除子任务和子任务关联的所有信息，且数据无法恢复，是否确认删除？
                this.dialogAttr.content = this.$i18n.t(
                  "label.projectManagement.delete.subtasks"
                );
              } else {
                this.dialogAttr.content = this.$i18n.t(
                  "label.weixin.confirm.delete"
                ); //'确认解除锁定？'
              }
            } else {
              this.noClick = flag === "noclick";
              detailApi.getPermissionById({ id: this.id }).then((res) => {
                this.noClick = false;
                if (res.data.isDelete && !res.data.isLocked) {
                  this.dialogVisible = true;
                  this.dialogAttr.title = this.$i18n.t(
                    "component_setup_tabs_label_delete"
                  );
                  if (this.objId === "cloudcc_project") {
                    //删除该项目，将删除项目和项目关联的所有信息，且数据无法恢复，是否确认删除？
                    this.dialogAttr.content = this.$i18n.t(
                      "label.projectManagement.delete.item"
                    );
                  } else if (this.objId === "cloudccMilestone") {
                    //删除该里程碑，将删除里程碑和里程碑关联的所有信息，且数据无法恢复，是否确认删除？
                    this.dialogAttr.content = this.$i18n.t(
                      "label.projectManagement.delete.milestone"
                    );
                  } else if (this.objId === "cloudcc_problem") {
                    //删除该问题，将删除问题和问题关联的所有信息，且数据无法恢复，是否确认删除？
                    this.dialogAttr.content = this.$i18n.t(
                      "label.projectManagement.delete.question"
                    );
                  } else if (this.objId === "cloudccTask") {
                    // 删除该任务，将删除任务和任务关联的所有信息，且数据无法恢复，是否确认删除？
                    this.dialogAttr.content = this.$i18n.t(
                      "label.projectManagement.delete.task"
                    );
                  } else if (this.objId === "cloudccSubtask") {
                    // 删除该子任务，将删除子任务和子任务关联的所有信息，且数据无法恢复，是否确认删除？
                    this.dialogAttr.content = this.$i18n.t(
                      "label.projectManagement.delete.subtasks"
                    );
                  } else {
                    this.dialogAttr.content = this.$i18n.t(
                      "label.weixin.confirm.delete"
                    ); //'确认解除锁定？'
                  }
                } else {
                  // 该记录已锁定，请联系管理员
                  this.$message(
                    this.$i18n.t(
                      "vue_label_commonobjects_detail_the_administrator"
                    )
                  );
                }
              });
            }
          } else if (item.name === "Share") {
            //分享
            this.Getrules();
            this.$refs.isShare.open();
          } else if (item.name === "fabu") {
            //公告菜单下  标准按钮更改成 发布范围按钮 wzj  勿删勿删勿删
            this.isShowggRecord = true;
          } else if (item.name === "PrintQuote") {
            // 打印报价单
            this.previewPdf();
          } else if (item.name === "fromopportunity") {
            this.$refs.synchro.gettitle(item);
          } else if (item.name === "startsyncing ") {
            this.$refs.synchro.gettitle(item);
          } else if (item.name === "Edit") {
            // 编辑
            this.noClick = flag === "noclick";
            detailApi.getPermissionById({ id: this.id }).then((res) => {
              this.noClick = false;
              if (res.data.isEdit) {
                // 如果是日程预约，不用标准弹窗。
                if (this.objId === "appointment") {
                  this.$router.push({
                    path: "/scheduleappointment/bookingdetails",
                    query: {
                      id: this.id,
                    },
                  });
                } else {
                  if (
                    this.prefix === "021" ||
                    this.prefix === "011" ||
                    this.prefix === "032" ||
                    this.prefix === "092" ||
                    this.prefix === "002"
                  ) {
                    // this.relatedlistIds = this.$refs.listChild.relatedlistIds;
                  }
                  this.$nextTick(() => {
                    this.$refs.createEditObj.objId = this.objId;
                    this.$refs.createEditObj.edit();
                  });
                }
              } else {
                // 抱歉，您无权编辑该条数据，请联系系统管理员。
                this.$message(this.$i18n.t("label.notEdit.message"));
              }
            });
          } else if (item.name === "Clone") {
            // 复制
            this.copyType = item.name;
            this.$nextTick(() => {
              this.$refs.createEditObj.objId = this.objId;
              this.$refs.createEditObj.copy();
            });
          } else if (item.name === "Submit") {
            //提交
            // this.$refs.listChild.openApproval(0);
          } else if (item.name === "Return Pool") {
            // 退回公海池
            this.seaShow = true;
            // location.reload()
            if (this.objId === "account") {
              // this.selMarketsea("account");
              this.valSea = "account";
            } else if (this.objId === "lead") {
              // this.selMarketsea("lead");
              this.valSea = "lead";
            }
          } else if (item.name === "Save and Sync to QB") {
            this.Synchronization();
          } else if (item.name === "Print Asset Code") {
            // 服务云资产对象下打印标签
            this.$refs.codeStyleDialog.openDialog(this.id);
          } else if (item.name === "QBtoCC") {
            this.QBmessCC = this.$i18n.t("front-QBtoCC-changing");
            this.QBtfCC = true;
            if (this.objectApi === "cloudccproceed") {
              this.noClick = flag === "noclick";
              detailApi.syncPaymentFromQB({ id: this.id }).then((res) => {
                this.noClick = false;
                this.QBtfCC = false;
                this.refreshAll();
                this.QBmessCC = "";
                if (res.result) {
                  if (res.data == "") {
                    this.$message.success(
                      this.$i18n.t("label.projectManagement.update.succeeded")
                    );
                  } else {
                    if (res.data.length > 0) {
                      let lastobj = res.data[res.data.length - 1];
                      let lastarr = lastobj.split(",");
                      let succmess = Number(
                        lastarr[0].substring(2, lastarr[0].length - 1)
                      );
                      let errmess = Number(
                        lastarr[1].substring(3, lastarr[0].length - 1)
                      );
                      if (succmess > 0 && errmess == 0) {
                        this.$message.success(
                          this.$i18n.t("label.projectManagement.update.succeeded")
                        );
                      } else {
                        this.$message.error(
                          this.$i18n.t("front-verkoop-bywerking-error")
                        );
                      }
                    }
                  }
                }
              });
            } else if (this.objectApi === "Invoices") {
              this.noClick = flag === "noclick";
              detailApi.syncInvoicesFromQB({ id: this.id }).then((res) => {
                this.noClick = false;
                this.QBtfCC = false;
                this.refreshAll();
                this.QBmessCC = "";
                if (res.result) {
                  if (res.data == "") {
                    this.$message.success(
                      this.$i18n.t("label.projectManagement.update.succeeded")
                    );
                  } else {
                    if (res.data.length > 0) {
                      let lastobj = res.data[res.data.length - 1];
                      let lastarr = lastobj.split(",");
                      let succmess = Number(
                        lastarr[0].substring(2, lastarr[0].length - 1)
                      );
                      let errmess = Number(
                        lastarr[1].substring(3, lastarr[0].length - 1)
                      );
                      if (succmess > 0 && errmess == 0) {
                        this.$message.success(
                          this.$i18n.t("label.projectManagement.update.succeeded")
                        );
                      } else {
                        this.$message.error(
                          this.$i18n.t("front-verkoop-bywerking-error")
                        );
                      }
                    }
                  }
                }
              });
            }
          } else if (item.name === "CCtoQB") {
            this.QBmessCC = this.$i18n.t("front-QBtoCC-sinchronies");
            this.QBtfCC = true;
            if (this.objectApi === "cloudccproceed") {
              this.noClick = flag === "noclick";
              detailApi.cloudccPaymentToQBPayment({ id: this.id }).then((res) => {
                this.noClick = false;
                this.QBtfCC = false;
                this.QBmessCC = "";
                if (res.result) {
                  if (res.data == "") {
                    this.$message.success(
                      this.$i18n.t("label.companyprofile.syncsuccess")
                    );
                  } else {
                    if (res.data.length > 0) {
                      let lastobj = res.data[res.data.length - 1];
                      let lastarr = lastobj.split(",");
                      let succmess = Number(
                        lastarr[0].substring(2, lastarr[0].length - 1)
                      );
                      let errmess = Number(
                        lastarr[1].substring(3, lastarr[0].length - 1)
                      );
                      if (succmess > 0 && errmess == 0) {
                        this.$message.success(
                          this.$i18n.t("label.companyprofile.syncsuccess")
                        );
                      } else {
                        this.$message.error(
                          this.$i18n.t("lable.Synchronization.failure")
                        );
                      }
                    }
                  }
                }
              });
            } else if (this.objectApi === "Invoices") {
              this.noClick = flag === "noclick";
              detailApi
                .cloudccInvoicesToQBInvoices({ id: this.id })
                .then((res) => {
                  this.noClick = false;
                  this.QBtfCC = false;
                  this.QBmessCC = "";
                  if (res.result) {
                    if (res.data == "") {
                      this.$message.success(
                        this.$i18n.t("label.companyprofile.syncsuccess")
                      );
                    } else {
                      if (res.data.length > 0) {
                        let lastobj = res.data[res.data.length - 1];
                        let lastarr = lastobj.split(",");
                        let succmess = Number(
                          lastarr[0].substring(2, lastarr[0].length - 1)
                        );
                        let errmess = Number(
                          lastarr[1].substring(3, lastarr[0].length - 1)
                        );
                        if (succmess > 0 && errmess == 0) {
                          this.$message.success(
                            this.$i18n.t("label.companyprofile.syncsuccess")
                          );
                        } else {
                          this.$message.error(
                            this.$i18n.t("lable.Synchronization.failure")
                          );
                        }
                      }
                    }
                  }
                });
            } else if (this.objectApi === "cloudccorder") {
              this.noClick = flag === "noclick";
              detailApi.cloudccOrderToQBEstimate({ id: this.id }).then((res) => {
                this.noClick = false;
                this.QBtfCC = false;
                this.QBmessCC = "";
                if (res.result) {
                  if (res.data == "") {
                    this.$message.success(
                      this.$i18n.t("label.companyprofile.syncsuccess")
                    );
                  } else {
                    if (res.data.length > 0) {
                      let lastobj = res.data[res.data.length - 1];
                      let lastarr = lastobj.split(",");
                      let succmess = Number(
                        lastarr[0].substring(2, lastarr[0].length - 1)
                      );
                      let errmess = Number(
                        lastarr[1].substring(3, lastarr[0].length - 1)
                      );
                      if (succmess > 0 && errmess == 0) {
                        this.$message.success(
                          this.$i18n.t("label.companyprofile.syncsuccess")
                        );
                      } else {
                        this.$message.error(
                          this.$i18n.t("lable.Synchronization.failure")
                        );
                      }
                    }
                  }
                }
              });
            }
          }
          //查看资产BOM
          else if (item.name === "View Asset BOM") {
            this.routers();
            //复制资产BOM
          } else if (item.name === "Copy Asset BOM") {
            if (this.isOpenAssetBom) {
              this.handleAssetBOM("copy");
            } else {
              // this.$message.warning('请联系管理员，您不具有复制资产BOM的权限')
              this.$message.warning(this.$i18n.t("assetbom.copy.notpermission"));
            }
            //删除资产BOM
          } else if (item.name === "Delete Asset BOM") {
            if (this.isOpenAssetBom) {
              this.handleAssetBOM("del");
            } else {
              // this.$message.warning('请联系管理员，您不具有删除资产BOM的权限')
              this.$message.warning(
                this.$i18n.t("assetbom.delete.notpermission")
              );
            }
            //转移资产BOM
          } else if (item.name === "Transfer Asset BOM") {
            if (this.isOpenAssetBom) {
              this.$refs.assetsBOM.assetsTransfer();
            } else {
              // this.$message.warning('请联系管理员，您不具有转移资产BOM的权限')
              this.$message.warning(
                this.$i18n.t("assetbom.transferowner.notpermission")
              );
            }
            //迁移资产BOM
          } else if (item.name === "Move Asset BOM") {
            if (this.isOpenAssetBom) {
              this.$refs.assetsBOM.assetsMove();
            } else {
              // this.$message.warning('请联系管理员，您不具有迁移资产BOM的权限')
              this.$message.warning(
                this.$i18n.t("assetbom.transfer.notpermission")
              );
            }
            //订单产品
          } else if (item.name === "Order To Asset BOM") {
            this.$refs.assetsBOM.orderProduct(this.heightLight, this.layoutid);
            //产品
          } else if (item.name === "Product To Asset BOM") {
            this.$refs.assetsBOM.assetsProduct();
          } else if (item.name === "Delete Draft") {
            this.noClick = flag === "noclick";
            detailApi.getPermissionById({ id: this.id }).then((res) => {
              this.noClick = false;
              if (res.data.isDelete) {
                // 删除草稿
                this.dialogAssembly(this.id, "draftdel");
              } else {
                // 抱歉，您无权编辑该条数据，请联系系统管理员。
                this.$message(
                  this.$i18n.t("vue_label_notice_nopermission_delete")
                );
              }
            });
          } else if (item.name === "Publish") {
            // 发布
            let params = {
              id: this.id,
            };
            this.noClick = flag === "noclick";
            detailApi.ismasterVersion(params).then((res) => {
              this.noClick = false;
              this.releasedialogVisible = true;
              this.affectTranslation = res.data.affectTranslation;
              this.markNewVersion = res.data.markNewVersion;
            });
          } else if (item.name === "Change Record Type") {
            this.$nextTick(() => {
              // 更改记录类型
              this.$refs.createEditObj.objId = this.objId;
              this.$refs.createEditObj.edit(
                {},
                this.$refs.listChild.konwrecordType
              );
            });
          } else if (item.name === "Edit as Draft") {
            // 作为草稿编辑
            this.dialogAssembly(this.id, "draftedit");
          } else if (item.name === "Archive") {
            // 归档
            this.dialogAssembly(this.id, "file");
          } else if (item.name === "Restore") {
            // 还原
            this.dialogAssembly(this.id, "recovery");
          } else if (item.name === "Synchronize Opportunity") {
            // 同步报价行至业务机会
            this.fullscreenLoading = true;
            this.noClick = flag === "noclick";
            detailApi
              .syncQuoteToOpportunity({ quoteid: this.id })
              .then(() => {
                this.noClick = false;
                this.$message.success(
                  this.$i18n.t("label.companyprofile.syncsuccess")
                );
                this.fullscreenLoading = false;
              })
              .catch(() => {
                this.fullscreenLoading = false;
              });
          } else if (item.name === "Generate Order") {
            this.fullscreenLoading = true;
            this.noClick = flag === "noclick";
            detailApi.generateOrder({ id: this.id }).then((res) => {
              this.noClick = false;
              this.fullscreenLoading = false;
              if (res.returnCode === "1") {
                this.$router.push(`/commonObjects/detail/${res.data}/DETAIL`);
              } else {
                this.$message.error(res.returnInfo);
              }
            });
          } else if (
            item.name === "New Order" &&
            this.objectApi === "Opportunity"
          ) {
            // 业务机会新建订单 准备带值 客户名称  业务机会  价格手册  币种  创建记录时间
            this.getInitValue(
              [
                { oldField: "khmc", newField: "accountid" },
                { oldField: "currency", newField: "currency" },
                { oldField: "pricebook2id", newField: "pricebook2id" },
                { oldField: "name", newField: "opportunityid" },
              ],
              "specialOrder"
            );
          } else if (item.name === "New Order" && this.objectApi === "quote") {
            // 报价单详情页新建订单,客户名称、业务机会名称、报价单、客户折扣总计、折扣、客户折扣、税率、调整、总计、价格手册、币种字段带值
            this.orignObj = "quote";
            this.getInitValue([
              { oldField: "khmc", newField: "accountid" },
              { oldField: "ywjhmc", newField: "opportunityid" },
              { oldField: "name", newField: "quote" },
              {
                oldField: "totalcustomerdiscountamount",
                newField: "discountamount",
              },
              { oldField: "customerdiscount", newField: "discount" },
              { oldField: "tax", newField: "tax" },
              { oldField: "ratetype", newField: "ratetype" },
              { oldField: "adjustment", newField: "adjustment" },
              { oldField: "totalamount", newField: "totalamount" },
              { oldField: "pricebook2id", newField: "pricebook2id" },
              { oldField: "currency", newField: "currency" },
            ]);
            this.getObjInfo({ id: "20210525cloudccorder" });
          } else if (
            item.name === "New Contract" &&
            this.objectApi === "cloudccorder"
          ) {
            this.getObjInfo({ id: "201100000004911Amewy" });
          } else if (
            item.name === "New Quote" &&
            this.objectApi === "Opportunity"
          ) {
            // 业务机会新建报价单带值  客户  币种 价格手册 业务机会
            this.getInitValue(
              [
                { oldField: "khmc", newField: "khmc" },
                { oldField: "currency", newField: "currency" },
                { oldField: "pricebook2id", newField: "pricebook2id" },
                { oldField: "name", newField: "ywjhmc" },
              ],
              "specialQuote"
            );
            this.getObjInfo({ id: "201100000006130OosPK" });
          } else if (
            item.name === "New Invoice" &&
            (this.objectApi === "Opportunity" || this.objectApi === "quote")
          ) {
            // 业务机会新建发票
            if (this.objectApi === "Opportunity") {
              // 从录入带值
              // this.$refs.listChild.$refs.relationListObj.forEach((ele) => {
              //   if (ele.objectInfo.objName === "opportunitypdt") {
              //     this.opportunitySecInfo = ele.objectInfo.data;
              //   }
              // });
              // 业务机会新建发票按钮 带值问题
              this.getInitValue(
                [
                  { oldField: "khmc", newField: "account_name" },
                  { oldField: "currency", newField: "currency" },
                  { oldField: "pricebook2id", newField: "pricebook2id" },
                  { oldField: "name", newField: "opportunity" },
                  { oldField: "cloudccorder", newField: "cloudccorder" },
                ],
                "specialInvoice"
              );
              // 获取 业务机会关联的客户的联系人
              this.noClick = flag === "noclick";
              detailApi
                .getRelatedAccountInfo({
                  id: this.id,
                  keyword: "Opportunity",
                })
                .then((res) => {
                  this.noClick = false;
                  if (res.data[0].contactId && res.data[0].contactId !== "") {
                    this.opportunityInfo.contact_name = [
                      {
                        id: res.data[0].contactId,
                        value: res.data[0].contactName,
                      },
                    ];
                  }
                  this.getObjInfo({ id: "invoices" });
                });
            } else {
              // 报价单新建发票
              let res = true;
              //获取发票的相关列表
              // this.$refs.listChild.$refs.relationListObj.forEach((ele) => {
              //   if (ele.objectInfo.objName === "Invoices") {
              //     res = false;
              //     // 判断发票相关列表是否有记录
              //     if (ele.objectInfo.data.length > 0) {
              //       this.showQuoteIntro = true;
              //     } else {
              //       // 准备带值
              //       this.quoteInitValue();
              //     }
              //   }
              // });
              if (res) {
                // 准备带值
                this.quoteInitValue();
              }
            }
          } else if (item.name === "confirmReceipt") {
            this.invoicesDialogVisible = true;
          } else if (item.name === "Generate Contract") {
            this.fullscreenLoading = true;
            this.noClick = flag === "noclick";
            detailApi.generateContract({ id: this.id }).then((res) => {
              this.noClick = false;
              this.fullscreenLoading = false;
              if (res.returnCode === "1") {
                this.$router.push(`/commonObjects/detail/${res.data}/DETAIL`);
              } else {
                this.$message.error(res.returnInfo);
              }
            });
          } else if (
            item.name === "Generate Invoice" &&
            this.objId === "20210525cloudccorder"
          ) {
            // 订单新建发票
            // 获取发票对象信息
            this.noClick = flag === "noclick";
            this.getInitValue([
              { oldField: "discountamount", newField: "discountamount" },
              { oldField: "discount", newField: "discount" },
              { oldField: "tax", newField: "tax" },
              { oldField: "ratetype", newField: "ratetype" },
              { oldField: "adjustment", newField: "adjustment" },
            ]);
            detailApi.getObjectGetObjectInfo({ id: "invoices" }).then((res) => {
              this.noClick = false;
              this.orderNameRecive = res.data.labelname;
              let orderid = this.id;
              // 判断全部订单是否为激活状态
              detailApi.isActiveOrder({ orderid }).then((res) => {
                if (res.result) {
                  this.orderInfo.orderlabel = this.bigTitle[0].fieldValue
                    ? this.bigTitle[0].fieldValue
                    : "";
                  this.activeList.map((item) => {
                    item.data.forEach((ditem) => {
                      if (ditem.left.apiname === "accountid") {
                        this.orderInfo.contract = ditem.left.data[0];
                      }
                      if (ditem.right.apiname === "accountid") {
                        this.orderInfo.contract = ditem.right.data[0];
                      }
                      // 价格手册
                      if (ditem.left.apiname === "pricebook2id") {
                        this.orderInfo.pricebook2id = ditem.left.data[0];
                      }
                      if (ditem.right.apiname === "pricebook2id") {
                        this.orderInfo.pricebook2id = ditem.right.data[0];
                      }
                      // billingaddress  账单地址
                      if (ditem.left.apiname === "billingaddress") {
                        this.orderInfo.billingaddress = ditem.left.children;
                      }
                      if (ditem.right.apiname === "billingaddress") {
                        this.orderInfo.billingaddress = ditem.right.children;
                      }
                      // shippingaddress  收货地址
                      if (ditem.left.apiname === "shippingaddress") {
                        this.orderInfo.shippingaddress = ditem.left.children;
                      }
                      if (ditem.right.apiname === "shippingaddress") {
                        this.orderInfo.shippingaddress = ditem.right.children;
                      }
                    });
                  });
                  this.$refs.orderCeateEditObj.add();
                }
              });
            });
          } else if (item.name === "Payment") {
            this.activeList.map((item) => {
              item.data.forEach((ditem) => {
                if (ditem.left.apiname === "account_name") {
                  this.customId = ditem.left.data[0].id;
                  this.invoiceInfo.customer = ditem.left.data[0];
                }
                if (ditem.right.apiname === "account_name") {
                  this.customId = ditem.right.data[0].id;
                  this.invoiceInfo.customer = ditem.right.data[0];
                }
                if (ditem.left.apiname === "tobepaidamount") {
                  this.invoiceInfo.tobepaidamount = ditem.left.value;
                }
                if (ditem.right.apiname === "tobepaidamount") {
                  this.invoiceInfo.tobepaidamount = ditem.right.value;
                }
                if (ditem.left.apiname === "contract") {
                  this.invoiceInfo.contract = ditem.left.data[0];
                }
                if (ditem.right.apiname === "contract") {
                  this.invoiceInfo.contract = ditem.right.data[0];
                }
                if (ditem.left.apiname === "cloudccorder") {
                  this.invoiceInfo.order = ditem.left.data[0];
                }
                if (ditem.right.apiname === "cloudccorder") {
                  this.invoiceInfo.order = ditem.right.data[0];
                }
              });
            });
            this.$refs.invoiceCeateEditObj.add();
          } else if (item.name === "Request Pay Online") {
            if (this.accountReceiver == "") {
              this.$message({
                type: "warning",
                message: this.$i18n.t("c131"),
              });
              return false;
            }
            //发起线上付款
            this.noClick = flag === "noclick";
            detailApi
              .getEmailObjectGetEmailPageInfo({ recordId: this.id })
              .then((email) => {
                this.noClick = false;
                if (email.result) {
                  if (email.data.relatedActiveMailSetting) {
                    detailApi
                      .getMessageByNum({ invoiceid: this.id })
                      .then((detail) => {
                        if (detail.result) {
                          if (
                            detail.data.isemail == "0" &&
                            detail.data.status == "0"
                          ) {
                            if (
                              detail.data.total == null ||
                              Number(detail.data.total) <= 0
                            ) {
                              this.$message({
                                type: "warning",
                                message: this.$i18n.t("front-email-payment-tips"),
                              });
                              return false;
                            } else {
                              this.printType = "invoice";
                              this.emailIsOperation = false;
                              this.printTitle = this.$i18n.t(
                                "front-email-payment-Invoice-preview"
                              );
                              detailApi
                                .getEmailOnlinePaySetUp()
                                .then((status) => {
                                  if (
                                    status.result &&
                                    this.invoiceCurrency != "CNY" &&
                                    status.data.ispaypol == "false" &&
                                    status.data.isvisa == "false"
                                  ) {
                                    this.$message({
                                      type: "warning",
                                      message: this.$i18n.t(
                                        "front-email-payment-Invoice-Tips"
                                      ),
                                    });
                                    return false;
                                  }
                                  if (
                                    status.result &&
                                    status.data.isonlinepay == "true" &&
                                    (status.data.isalipay == "true" ||
                                      status.data.iswxpay == "true" ||
                                      status.data.ispaypol == "true" ||
                                      status.data.isvisa == "true")
                                  ) {
                                    detailApi
                                      .getObjTemplateList({ objId: this.objId })
                                      .then((res) => {
                                        if (res.result) {
                                          let flg = true;
                                          res.data.forEach((element) => {
                                            if (element.isenable === "1") {
                                              flg = false;
                                            }
                                          });
                                          if (flg) {
                                            this.$message.error(
                                              this.$i18n.t(
                                                "front-email-payment-Invoice-preview-tips"
                                              )
                                            );
                                          } else {
                                            this.templateList = res.data;
                                            // 模板时间排序
                                            this.templateList.sort((a, b) => {
                                              let dateOne = new Date(
                                                a.lastmodifydate
                                              );
                                              let dateTow = new Date(
                                                b.lastmodifydate
                                              );
                                              if (dateOne === dateTow) {
                                                return 0;
                                              } else if (dateOne < dateTow) {
                                                return 1;
                                              } else {
                                                return -1;
                                              }
                                            });
                                            this.templateList.sort((a, b) => {
                                              if (b.isenable === "0") {
                                                return -1;
                                              } else {
                                                return 1;
                                              }
                                            });
                                            this.invoiceTemplateDialogVisible = true;
                                            this.closeEmailDialogFlg = 1;
                                            this.$refs.invoiceTemplate.init(
                                              this.fieldValue,
                                              this.templateList[0].templateid
                                            );
                                          }
                                        } else {
                                          this.$message({
                                            showClose: true,
                                            type: "error",
                                            message: res.returnInfo,
                                          });
                                        }
                                      });
                                  } else {
                                    this.$message.warning(
                                      this.$i18n.t(
                                        "front-email-payment-Invoice-preview-opening"
                                      )
                                    );
                                  }
                                });
                            }
                          } else {
                            this.$message({
                              type: "warning",
                              message: this.$i18n.t(
                                "front-invoice-online-pay-again"
                              ),
                            });
                          }
                        }
                      });
                  } else {
                    this.$message.warning(
                      this.$i18n.t(
                        "vue_label_email_You_have_not_activated_the_mailbox"
                      )
                    ); //您还未启用邮箱
                  }
                }
              });
          } else if (item.name === "Create Contact") {
            // 创建联系人
            this.ewobjectApi = "Contact";
            this.ewtabName = "联系人";
            this.ewprefix = "003";

            // 调接口把值带过去
            this.noClick = flag === "noclick";
            detailApi
              .epWechatToContactAndLead({
                id: this.id,
              })
              .then((res) => {
                this.noClick = false;
                if (100 == res.returnCode) {
                  this.ewId = {
                    label: this.bigTitle && this.bigTitle[0].fieldValue,
                    id: this.id,
                  };
                  this.ewData = res.data.contactshowinfo;

                  this.$refs.ewcreateEditObj.add();
                }
              });
          } else if (item.name === "Create Lead") {
            // 创建潜在客户
            this.ewobjectApi = "Lead";
            this.ewtabName = "潜在客户";
            this.ewprefix = "004";

            // 调接口把值带过去
            this.noClick = flag === "noclick";
            detailApi
              .epWechatToContactAndLead({
                id: this.id,
              })
              .then((res) => {
                this.noClick = false;
                if (100 == res.returnCode) {
                  this.ewId = {
                    label: this.bigTitle && this.bigTitle[0].fieldValue,
                    id: this.id,
                  };
                  this.ewData = res.data.leadshowinfo;

                  this.$refs.ewcreateEditObj.add();
                }
              });
          } else if (item.name === "CreateWorkOrder") {
            // 工作订单
            this.prefixCustomer = "s21";
            this.objectApiCustomer = "WorkOrder";
            this.objectNameCustomer = this.$i18n.t(
              "lable.PowerProcess.Workorder"
            );
            this.$nextTick(() => {
              this.$refs.ceateEditObjForCustomer.add();
            });
          } else if (item.name === "CreateContact") {
            // 联系人 Contact 003 label.contact
            this.prefixCustomer = "003";
            this.objectApiCustomer = "Contact";
            this.objectNameCustomer = this.$i18n.t("label.contact");
            this.$nextTick(() => {
              this.$refs.ceateEditObjForCustomer.add();
            });
          } else if (item.name === "CreateCase") {
            // 个案 cloudcccase 008 label.case
            this.prefixCustomer = "008";
            this.objectApiCustomer = "cloudcccase";
            this.objectNameCustomer = this.$i18n.t("label.case");
            this.$nextTick(() => {
              this.$refs.ceateEditObjForCustomer.add();
            });
          } else if (item.name === "CreateAccount") {
            // 客户 Account 001 label.account
            this.prefixCustomer = "001";
            this.objectApiCustomer = "Account";
            this.objectNameCustomer = this.$i18n.t("label.account");
            this.$nextTick(() => {
              this.$refs.ceateEditObjForCustomer.add();
            });
          } else if (item.name === "generate") {
            // PDF视图详情页中，省略选择模板的弹框，直接预览PDF
            // let data.templateId = this.templateId;
            // data.templateFile = this.templateId;
            let data = {
              templateId: this.templateId,
              templateFile: this.fieldValue,
              templateType: "",
            };
            this.closeSelectTemplateDialog(true, data);
          } else if (item.name === "send") {
            //模板标准发送功能
            this.printType = "quotation";
            this.emailIsOperation = true;
            this.closeEmailDialogFlg = 2;
            this.$refs.selectquotation.getFilesdata();
          }
          // 指定在延迟开始前调用
        },
        1000,
        {
          leading: true,
          trailing: false,
        }
      ),
      // 发票页面同步至quickbooks
      async Synchronization() {
        var data = {
          accessToken: localStorage.getItem("binding"),
          id: sessionStorage.getItem("id"),
        };
        const res = await detailApi.cloudccInvoicesToQBInvoices(data);
        if (res.result == true) {
          this.$message({
            type: "success",
            message: this.$i18n.t("label.companyprofile.syncsuccess"),
          });
          history.go(0);
        } else {
          this.$message({
            type: "warning",
            message: this.$i18n.t("lable.Synchronization.failure"),
          });
        }
      },
      // 控制公海池弹框隐藏
      clientPoolCancle() {
        this.seaShow = false;
      },
      // 新增/修改保存
      save() {
        //如果是保存刷新当前页面也用，跳转不需要
        if (this.copyType === "Clone") {
          this.$message.success(this.$i18n.t("label.search.saveok"));
        } else {
          // 局部刷新简要信息、相关列表和详细信息,PDF模板
          this.getBrief();
          this.GetRelationList(true);
          this.pdfTemplateLoad = true;
          this.getTemplateInfo();
        }
      },
      // 打印
      printDetail() {
        localStorage.getItem("openWater") == "true";
        window.open(
          `#/print-quotation/${this.fieldValue}/${this.objId}/${this.id}/${this.templateId}/${this.templateType}`,
          "_parent - URL",
          "height=700, width=1300, top=50, left=100, toolbar=no, menubar=no, scrollbars=no, resizable=no,location=no, status=no"
        );
      },
      closeDialog() {
        this.printVisible = false;
      },
      // 返回上一级
      goBack() {
        // this.$router.go(-1);
        this.$router.push({
          path: `/commonObjects/views/table/${this.objectApi}/${this.objId}/${this.prefix}`,
        });
      },
      previewPdf() {
        let token = VueCookies.get("JSESSIONID");
        this.pdfViewerAttr.pdfUrl = `api/quote/previewPdf?accessToken=${token}&quoteId=${this.id}`;
        this.pdfViewerAttr.isShow = true;
      },
      // 保存到文件
      saveToAppendix() {
        detailApi
          .SavePdfToFile({
            quoteId: this.id,
          })
          .then((res) => {
            // 保存成功
            if (res.result) {
              this.$message({
                showClose: true,
                type: "success",
                message: this.$i18n.t("label.search.saveok"),
              });
            } else {
              // 保存失败
              this.$message({
                showClose: true,
                type: "error",
                message: this.$i18n.t("savefail"),
              });
            }
            this.pdfViewerAttr.isShow = false;
          });
      },
      closeViewerDialog() {
        this.pdfViewerAttr.isShow = false;
      },
      // 确认
      confirm() {
        this.dialogVisible = false;
        if (
          this.dialogAttr.title ===
          this.$i18n.t("component_setup_tabs_label_delete")
        ) {
          const params = {
            id: this.id,
            objectApi: this.objectApi,
          };
          detailApi.GetDelete(params).then((res) => {
            if (res.result) {
              this.$message({
                showClose: true,
                type: "success",
                message: this.$i18n.t("label_tabpage_delsuccessz"),
              });
              // this.$router.go(-1);
              this.$router.push({
                path: `/commonObjects/views/table/${this.objectApi}/${this.objId}/${this.prefix}`,
              });
            } else {
              this.$message({
                showClose: true,
                type: "error",
                message: res.returnInfo,
              });
            }
          });
        } else {
          // 解除锁定
          detailApi.recordUnLocked({ id: this.id }).then(() => {
            this.lockingButton = {};
            // 解除锁定成功
            this.$message.success(
              this.$i18n.t("vue_label_commonobjects_detail_unlocksuccess")
            );
            this.refreshAll();
          });
        }
      },
      //相关列表定位
      getScroll(msg) {
        //进度条的高度
        let pbh = 0;
        //边框高度
        let borderHeight = pbh ? 10 : 0;
        //市场活动下roi卡片高度
        let roiHeight = 0;
        if (this.isShowType) {
          this.$refs.home.scrollTop =
            Number(msg) +
            this.briefHeight +
            Number(pbh) +
            borderHeight +
            Number(roiHeight);
        } else {
          this.$refs.home.scrollTop = Number(msg) + 98;
        }
      },
      guanbis() {
        if (this.isHeight === false) {
          this.height = "60px";
          this.isHeight = true;
          this.icons = "el-icon-d-arrow-left";
        } else {
          this.height = "100%";
          this.isHeight = false;
          this.icons = "el-icon-d-arrow-right";
        }
      },
      routers() {
        if (this.objectApi === "Account") {
          this.$router.push({
            path: "/commonObjects/customer",
            query: {
              ids: this.id,
              type: "Account",
              content: this.objectApi,
            },
          });
        } else if (this.objectApi === "Contact") {
          this.$router.push({
            path: "/commonObjects/customer",
            query: {
              ids: this.id,
              type: "Contact",
              content: this.objectApi,
            },
          });
        } else if (this.objectApi === "campaign") {
          // this.isCustomer = true
          // this.content = '市场层级结构'
          this.$router.push({
            path: "/commonObjects/customer",
            query: {
              ids: this.id,
              type: "Campaign",
              content: this.objectApi,
            },
          });
        } else if (this.objectApi === "Asset") {
          // 资产层级结构
          this.$router.push({
            path: "/commonObjects/customer",
            query: {
              ids: this.id,
              type: "Asset",
              content: this.objectApi,
            },
          });
        } else {
          this.isCustomer = false;
        }
      },
      async Getrules() {
        let params = {
          id: this.$router.history.current.params.id,
        };
        let res = await detailApi.GetSharegetShareRuleList(params);
        this.shares = res.data;
      },
      //进度条需要
      shuaxin() {
        // this.$refs.listChild.getFormAttr();
      },
      convert(val) {
        this.isconvert = val;
      },
      async getObjectGetObjectInfos(val) {
        let params = {
          id: val,
        };
        let res = await detailApi.getObjectGetObjectInfo(params);
        if (res.result) {
          this.isTag = res.data.istag;
        }
      },
      dataListes(val) {
        this.layoutid = val?.layoutId;
        this.activeList = val.dataList;
        this.activeList[0].data &&
          this.activeList[0].data.forEach((res) => {
            if (res.left.apiname == "email") {
              this.invoiceReceiver = res.left.value;
              return;
            }
            if (res.right.apiname == "email") {
              this.invoiceReceiver = res.right.value;
              return;
            }
          });
        this.activeList[0].data &&
          this.activeList[0].data.forEach((res) => {
            if (res.left.apiname == "account_name") {
              this.accountReceiver = res.left.value;
              return;
            }
            if (res.right.apiname == "account_name") {
              this.accountReceiver = res.right.value;
              return;
            }
          });
        this.activeList[0].data &&
          this.activeList[0].data.forEach((res) => {
            if (res.left.apiname == "total") {
              this.invoiceCurrency = res.left.value.slice(0, 3);
              return;
            }
            if (res.right.apiname == "total") {
              this.invoiceCurrency = res.right.value.slice(0, 3);
              return;
            }
          });
      },
      // 关闭弹窗
      handleClose() {
        this.dialogVisible = false;
      },
      handleCloses() {
        this.isPartnerCloud = false;
      },
      //详情简介跳转详情
      briefDetail(row) {
        if (row.fieldName === "ownerid") {
          // 所有人非队列可跳转
          if (row.fieldValueId && row.fieldValueId.slice(0, 3) === "005") {
            this.$router.push({
              path: `/commonObjects/detail/${row.fieldValueId}/DETAIL`,
              query: {
                viewId: "",
              },
            });
          } else {
            // 所有人队列不可跳转
            return false;
          }
        } else {
          detailApi
            .getPermissionById({
              id: row.fieldValueId,
            })
            .then((res) => {
              if (res.data.isQuery === true) {
                this.$router.push({
                  path: `/commonObjects/detail/${row.fieldValueId}/DETAIL`,
                  query: {
                    viewId: "",
                  },
                });
              } else {
                this.$message.warning(
                  this.$i18n.t("vue_label_notice_nopermission_view")
                  //"抱歉，您无权查看该条数据，请联系系统管理员。"
                );
              }
            });
        }
      },
      async getPartnerGetPartnerPermissions(val) {
        let params = {
          id: this.id,
        };
        let res = await detailApi.getPartnerGetPartnerPermission(params);
        if (res.result) {
          this.ismanagePartnerUser = res.data.managePartnerUser;
          this.ispartner = res.data.ispartner;
          this.userId = res.data.userId;
          if (this.ismanagePartnerUser) {
            let obj = {
              label: this.$i18n.t("label.query.detail.manageexuser"),
              name: "manageExternalUsers",
              //管理外部用户
            };
            this.moreButton.push(obj);
            this.unique(this.moreButton);
          }
          if (res.data.ispartner) {
            if (val === "Contact") {
              let buttons = [
                {
                  label: this.$i18n.t("label.query.detail.viewpartneruser"),
                  name: "viewPartners",
                  // 查看合作伙伴用户
                },
                {
                  label: this.$i18n.t("label.query.detail.editpartneruser"),
                  name: "editPartner",
                  // 编辑合作伙伴用户
                },
                {
                  label: this.$i18n.t("label.query.detail.forbidpartneruser"),
                  name: "disablePartner",
                  // 禁用合作伙伴用户
                },
                {
                  label: this.$i18n.t(
                    "vue_label_commonobjects_detail_cloud_as_a_user"
                  ), //"以用户身份登陆伙伴云",
                  name: "userPartner",
                  // 以用户身份登陆伙伴云
                },
              ];
              this.partner = buttons;
            } else {
              this.partner = [
                {
                  // 禁用合作伙伴
                  label: this.$i18n.t("label.query.detail.closepartner"),
                  name: "disablePartner",
                },
              ];
            }
          } else {
            if (val === "Account") {
              this.partner = [
                {
                  // 启用合作伙伴用户
                  label: this.$i18n.t("label.query.detail.openpartner"),
                  name: "enablePartne",
                },
              ];
            } else {
              this.partner = [
                {
                  // 启用合作伙伴用户
                  label: this.$i18n.t("label.query.detail.openpartneruser"),
                  name: "enablePartne",
                },
              ];
            }
          }
        }
      },
      external(val) {
        if (val.name === "enablePartne") {
          if (this.objectApi === "Account") {
            this.$confirm(
              // " 一旦将客户作为合作伙伴启用，它就会出现在isPartner字段上筛选过的报表和列表视图中。另外，合作伙伴用户可以从与此客户关联的联系人进行创建。是否确定？",
              this.$i18n.t("label.query.detail.openpartnermessage"),
              // "提示",
              this.$i18n.t("label.ccchat.message.new.error4"),
              {
                // 确定
                confirmButtonText: this.$i18n.t("label.ems.confirm"),
                // 取消
                cancelButtonText: this.$i18n.t("button_partner_cancel"),
                type: "warning",
              }
            ).then(() => {
              this.getPartnerPartnerEnableAccounts();
              this.freshen();
            });
          } else {
            this.getPartnerPartnerEnableContacts();
            this.freshen();
          }
        } else if (val.name === "disablePartner") {
          this.$confirm(
            this.$i18n.t("vue_label_commonobjects_detail_customers"),
            //"永久禁用外部用户的客户将禁止所有与客户关联的外部用户，同时从与他们关联的所有社区、组、小组、权限集和共享规则中删除。同时，将永久删除与该账户关联的角色和组，同时不会出现在回收站中。是否确定？",
            // "提示",
            this.$i18n.t("label.ccchat.message.new.error4"),
            {
              // 确定
              confirmButtonText: this.$i18n.t("label.ems.confirm"),
              // 取消
              cancelButtonText: this.$i18n.t("button_partner_cancel"),
              type: "warning",
            }
          ).then(() => {
            if (this.objectApi === "Account") {
              this.getPartnerPartnerEnableAccounts();
              this.freshen();
            } else if (this.objectApi === "Contact") {
              this.getPartnerPartnerEnableContacts();
              this.freshen();
            }
          });
        } else if (val.name === "viewPartners") {
          this.$router.push({
            path: `/commonObjects/externalUsers/${this.userId}/${this.prefix}/${this.objectApi}`,
          });
        } else if (val.name === "editPartner") {
          this.$router.push({
            path: `/commonObjects/externalUsers/${this.userId}/${this.prefix}/${this.objectApi}`,
            query: {
              edit: false,
            },
          });
        } else if (val.name === "userPartner") {
          this.getPartnerQueryAllPartnerByLogins();
        }
      },
      //客户下启用禁用合作伙伴
      async getPartnerPartnerEnableAccounts() {
        let params = {
          id: this.id,
        };
        await detailApi.getPartnerPartnerEnableAccount(params);
      },
      //联系人下启用禁用合作伙伴
      async getPartnerPartnerEnableContacts() {
        let params = {
          id: this.id,
        };
        let res = await detailApi.getPartnerPartnerEnableContact(params);
        if (res.result) {
          if (res.data.isfirst !== null && res.data.isfirst !== false) {
            this.dataList = res.data;
            this.$router.push({
              path: `/commonObjects/externalUsers/${this.id}/${this.prefix}/${this.objectApi}`,
              query: {
                newBuild: true,
              },
            });
            // this.$nextTick(() => {
            //   this.$refs.createEditObj.objId = this.objId
            //   this.$refs.createEditObj.contacts()
            // })
          } else {
            // 启用成功
            this.$message.success(this.$i18n.t("label.group.isusing.success"));
            this.freshen();
          }
        }
      },
      //获取所有能登录的伙伴云链接
      async getPartnerQueryAllPartnerByLogins() {
        let params = {
          id: this.id,
        };
        let res = await detailApi.getPartnerQueryAllPartnerByLogin(params);
        if (res.result) {
          this.tableData = res.data;
          this.isPartnerCloud = true;
        }
      },
      freshen() {
        this.getBrief();
      },
      Refreshes(val) {
        if (val === "true") {
          this.freshen();
        }
      },
      //togantt
      toGannt(type) {
        if (type === "kanbanTaskPage") {
          // 修改看板任务本地存储值的问题，默认第一次进入看板是里程碑页面
          let a = "ffe20201207task11";
          localStorage.setItem("key", a);
        }
        this.$router.push({
          path: "/gantt",
          query: {
            proId: this.id,
            proName: this.fieldValue,
            isGantt: type,
          },
        });
      },
      //数组去重
      unique(arr) {
        for (var i = 0; i < arr.length; i++) {
          for (var j = i + 1; j < arr.length; j++) {
            if (arr[i].name == arr[j].name) {
              //第一个等同于第二个，splice方法删除第二个
              arr.splice(j, 1);
              j--;
            }
          }
        }
        return arr;
      },
      //回到顶部
      backtop() {
        document.getElementById("contentMainBox").scrollTop = 0;
      },
      // 复制、删除资产BOM
      handleAssetBOM(flag) {
        this.$refs.assetsBOM.handleAsset(flag);
      },
      //编辑报价行
      editProduct() {
        this.$router.push(`/quotation/editQuotation/${this.id}`);
      },
      //编辑阶梯折扣
      editDiscount() {
        this.$router.push({
          path: `/quotation/ladderDiscount`,
          query: {
            id: this.id,
          },
        });
      },
      typeedit() {
        this.centerDialogVisible = true;
        this.queryDataCategory();
      },
      thumbsUp(type) {
        if (type == "up") {
          this.thumbsUpvalue = this.thumbsUpvalue + 1;
        } else {
          this.thumbsDownvalue = this.thumbsDownvalue + 1;
        }
      },
      langechange(id) {
        this.$router.push({
          path: `/commonObjects/detail/${id}/DETAIL`,
        });
        this.findArticleIdTranslate();
      },

      //新建文章
      addarticle() {
        this.$refs.createEditObj.add();
      },
      // 两个数组中不同项输出
      filterNode(value, data) {
        if (!value) return true;
        return data.label.indexOf(value) !== -1;
      },
      // 数组扁平化
      treeToList(tree) {
        var queen = [];
        var out = [];
        queen = queen.concat(tree);
        while (queen.length) {
          var first = queen.shift();
          if (first.children) {
            queen = queen.concat(first.children);
            delete first["children"];
          }
          out.push(first);
        }
        return out;
      },
      // 数组转成树状结构
      transformTree(list) {
        const tree = [];
        for (let i = 0, len = list.length; i < len; i++) {
          if (!list[i].pid) {
            const item = this.queryChildren(list[i], list);
            tree.push(item);
          }
        }
        return tree;
      },
      queryChildren(parent, list) {
        const children = [];
        for (let i = 0, len = list.length; i < len; i++) {
          if (list[i].pid === parent.id) {
            const item = this.queryChildren(list[i], list);
            children.push(item);
          }
        }
        if (children.length) {
          parent.children = children;
        }
        return parent;
      },
      // 知识文章分类保存
      checksure() {
        var arr = this.treeToList(this.$refs.tree.children);
        arr.map((o) => {
          return Object.assign(o, { categoryId: o.id });
        });
        let params = {
          dataCategoryId: JSON.stringify(arr),
          articleId: this.id,
        };

        detailApi.saveDataCategory(params).then(() => {
          this.$refs.tree.setCheckedKeys([]);
          this.fromData = [];
          this.centerDialogVisible = false;
          this.queryDataCategorySelection();
        });
      },
      //取消分类弹窗
      checkcancel() {
        this.$refs.tree.setCheckedKeys([]);
        this.fromData = [];
        this.centerDialogVisible = false;
      },
      // 查询已选择数据分类
      queryDataCategorySelection() {
        let params = {
          articleId: this.id,
        };
        detailApi.queryDataCategorySelection(params).then((res) => {
          this.data = res.data;
          this.dataNum = res.data.length;
          for (var i = 0; i < this.data.length; i++) {
            this.$set(this.data[i], "openBool", true);
          }
        });
      },

      // 查询数据分类和数据分类组
      queryDataCategory() {
        detailApi.queryDataCategory({ articleId: this.id }).then((res) => {
          this.fromData = res.data;
          var checkList = [];
          this.forEachObj({ children: res.data }, (item) => {
            if (item.selected === true) {
              checkList.push(item.id);
            }
          });
          this.$refs.tree.setCheckedKeys(checkList);
        });
      },

      // 遍历子节点
      forEachObj({ children }, func) {
        children?.forEach((item) => {
          func(item);
          this.forEachObj(item, func);
        });
      },

      // 知识文章分类选中操作
      handleCheckChange(data, node) {
        const checkBool = node.checkedKeys.includes(data.id);
        data.selected = checkBool;
        this.forEachObj(data, (item) => {
          this.$refs.tree.setChecked(item.id, false);
          item.selected = false;
          item.disabled = checkBool;
        });
      },
      // 改变添加属性的布尔值
      changeopenBool(val) {
        this.data[val].openBool = !this.data[val].openBool;
        if (this.data.every(this.checkOpen)) {
          this.allon = false;
        } else {
          this.allon = true;
        }
      },
      // 检测openBool函数
      checkOpen(val) {
        return val.openBool === true;
      },
      // 全部展开
      openBoolall() {
        this.allon = false;
        this.data.forEach((item) => {
          item.openBool = true;
        });
      },
      // 全部折叠
      closeBoolall() {
        this.allon = true;
        this.data.forEach((item) => {
          item.openBool = false;
        });
      },
      //简介为url跳转
      jumpTo(url) {
        if (url.indexOf("http://") !== -1) {
          window.open(url);
        } else {
          window.open("http://" + url);
        }
      },
      lockBtn() {
        //判断是否有解除锁定按钮
        let isLock = this.buttonList.some((val) => {
          return val.name === "unlock";
        });
        //当锁定状态为true,并且有解除锁定按钮时才会有解除按钮功能
        if (this.isLocked && isLock) {
          // 解除锁定
          this.dialogVisible = true;
          this.dialogAttr.title = this.$i18n.t("label.unlock");
          this.dialogAttr.content = this.$i18n.t(
            "vue_label_commonobjects_detail_unlockconfirm"
          ); //'确认解除锁定？'
        } else {
          this.$message({
            showClose: true,
            message: this.$i18n.t("label.you.do.not.have.permission.to.operate."), // '您没有权限进行操作'
          });
        }
      },
      // 关闭展示所有标签的弹窗
      handleCloseAllTags() {
        this.showAllTags = false;
      },
      /**
       * @description: 分屏视图切换数据刷新活动组件
       */

      multiTask() {
        this.$refs.activitys.init();
      },
    },
    watch: {
      $route(val) {
        if(this.id!=val.params.id){
          this.id=val.params.id;
          // 有动态tab时默认显示动态，没有动态默认显示第一个
          if(this.isShowdynamicPublics){
            this.activeName = 'second';
          }else{
            this.activeName=this.tabData&&this.tabData[0].relatedlistId
          }
          this.objectApiNumber = this.$store.getters.getObjectNumber;
          this.pdfTemplateLoad = true;
          this.init();
          this.getDetail();
          this.GetRelationList(true);
          this.getSetupInfo();
          this.getTemplateInfo();
        }
      },
      activeName: {
        immediate: true,
        handler(val) {
          if (val === "second" && this.isShowType === true) {
            this.getSetupInfo();
          } else {
            this.handleClick();
          }
        },
      },
      realObjId(val) {
        // 控制甘特图按钮显隐
        if (val && val === "cloudcc_project") {
          this.isShowGanttButton = true;
        } else {
          this.isShowGanttButton = false;
        }
      },
      screenWidth: {
        immediate: true,
        handler(newValue) {
          if (!this.timer) {
            this.screenWidth = newValue;
            this.meunsFilter();
            this.timer = true;
            let that = this;
            setTimeout(function () {
              that.timer = false;
            }, 200);
          }
          this.$nextTick(() => {
            // this.$refs.listChild.setRelationNameWidth();
          });
        },
      },
      id(newVal, oldVal) {
        if (newVal != oldVal) {
          this.getIsHasGroup();
          this.$CCDK.CCDetail.setDetailId(this.id) // 详情页记录ID   给自定义页面内嵌使用  id变化更新localStorage
        }
      },
      labelName() {},
      // 监听分屏视图数据变化
      dataId(nval) {
        this.id = nval;
        // this.$refs.listChild.id = nval;
        this.init();
        // this.$refs.listChild.init();
      },
      tabName(nval) {
        // 联系人
        if (nval === this.$i18n.t("label_tabpage_contactsz")) {
          let obj = {
            objectApi: "Contact",
          };
          this.resetObjectInfo(obj);
        }
      },
      objId(val) {
        if (val !== "" && val !== undefined) {
          this.getObjectGetObjectInfos(val);
        }
        if (val === "task" || val === "event") {
          this.activeName = "second";
        } else if (this.isShowType === false) {
          this.activeName = "second";
        }
      },
      objectApi(val) {
        if (
          this.objectApi === "Invoices" ||
          this.objectApi === "cloudccproceed" ||
          this.objectApi === "cloudccorder"
        ) {
          this.startQB();
        }
        if (val === "Task" || val === "Event") {
          this.activeName = "second";
        } else if (this.isShowType === false) {
          this.activeName = "second";
        }
      },
      filterText(val) {
        this.$refs.tree.filter(val);
      },
    },
    computed: {
      eventTap() {
        let str = "";
        if (this.objectApi === "Event") {
          str = this.$i18n.t("label.event");
        }
        return str;
      },
    },
    destroyed() {
      localStorage.removeItem("detailRecordId");
      this.$bus.$off("updateDetailRecordId");
      this.$bus.$off("chooseListItem");
    },
  };
  </script>

  <style scoped lang="scss">
  .detai-box {
    width: 100%;
    height: calc(100% - 64px);
    background: #f2f2f5;
    // padding: 10px 0px 0px 10px !important;
  }
  .noClick {
    pointer-events: none;
  }
  .downXiala {
    cursor: pointer;
    width: 24px;
    height: 24px;
    margin-right: 10px;
    float: right;
  }
  .li {
    padding: 0px 10px !important;
  }

  .layoutSwitchBox {
    position: absolute;
    left: calc(66.7% - 26px);
    top: 0;
    text-align: center;
    height: 32px;
    line-height: 32px;
    cursor: pointer;
    z-index: 100;

    svg {
      width: 41px;
      height: 49px;
    }
  }

  ::v-deep .el-dialog.qunce-dialog {
    padding: 20px;
    border-radius: 8px;
    .el-dialog__header {
      border-bottom: 1px solid #dcdcdc;
      padding: 0 0 10px;
      .el-dialog__title {
        font-size: 14px;
        font-weight: 900;
      }
    }
    .el-dialog__body {
      padding: 0;
      padding-top: 17px;
    }
    .el-button {
      margin-top: 35px;
      padding: 10px 15px;
    }
    .cancel {
      margin-left: calc(50% - 70px);
    }
  }

  .pop_box .moreButton,
  .pop_small .moreButton {
    margin: 0 -12px;
    min-height: 26px;
  }
  .pop_box .moreButton li,
  .pop_small .moreButton li {
    min-height: 26px;
    padding: 0 10px;
    font-size: 12px;
    padding: 0;
    text-align: center;
    line-height: 26px;
    color: #3e3e3c;
  }

  .pop_box .moreButton li:hover,
  .pop_small .moreButton li:hover {
    background: #f3f2f2;
    color: #006dcc;
  }
  .moreButtons li {
    min-height: 26px;
    padding: 0 10px;
    font-size: 12px;
    padding: 0;
    text-align: center;
    line-height: 26px;
    color: #3e3e3c;
  }
  .moreButtons li:hover {
    background: #f3f2f2;
    color: #006dcc;
  }
  </style>
  <style scoped lang="scss">
  .isEnableBox {
    align-items: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-content: center;
    height: 400px;
  }
  ::v-deep .dynamic {
    .Post {
      margin-right: 0;
    }
  }
  ::v-deep .dynamic-public .dynamic {
    min-height: 50px;
  }
  ::v-deep .dynamic-public .detail-box {
    // padding: 10px 0px 13px;
    padding: 0;
  }
  .home {
    height: 100%;
    width: 100%;
    position: relative;
    height: 100%;
    border-radius: 3px;

    .noPermissionBox {
      // width: 100%;
      text-align: center;
      position: absolute;
      top: calc(50% - 136px);
      left: calc(50% - 176.5px);
      svg {
        width: 353px;
        height: 194px;
        margin: 0 auto;
      }
      .noPermissionTip {
        margin: 10px 0;
        font-size: 20px;
        font-weight: bold;
      }
    }

    .contentBox {
      position: sticky;
      left: 0px;
      right: 0px;
      z-index: 999;
      top: 0.1px;
      width: 100%;
    }
    .contentBoxs {
      z-index: 999;
      top: 104px;
    }
  }

  ::v-deep .el-card__body {
    padding: 0px;
  }

  .box-card {
    width: 100%;
    border: 1px solid rgba(221, 219, 218, 1);
    border-radius: 0 !important;
    ul {
      padding: 10px;
      box-sizing: border-box;

      li {
        font-size: 12px;
        line-height: 30px;
        width: 100%;
        display: flex;
        justify-content: flex-start;

        .label_span {
          width: 80px;
          display: inline-block;
          margin-right: 20px;
        }

        .color_s span {
          width: 20px;
          height: 20px;
          background: green;
          margin: 5px;
          display: inline-block;
        }
      }
    }

    ::v-deep .el-radio__label {
      font-size: 12px;
    }
  }

  .box-card {
    width: 100%;
    border: 1px solid rgba(221, 219, 218, 1);
    ::v-deep .el-card__body {
      padding: 0;
    }
    border-radius: 0 !important;
    .introduce {
      width: 100%;
      overflow-x: hidden;
      height: auto;
      display: flex;
      .introduce_title {
        font-size: 12px;
        color: #3e3e3c;
        letter-spacing: 0;
        text-align: justify;
        line-height: 22px;
        min-width: 80px;
        display: inline-block;
        white-space: nowrap;
        svg {
          margin-left: 1px;
          margin-top: -2px;
          cursor: pointer;
          width: 18px;
          height: 18px;
        }
      }

      .introduce_cet {
        font-size: 14px;
        color: #080707;
        letter-spacing: 0;
        text-align: justify;
        line-height: 18px;
        display: inline-block;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        max-width: 300px;
        ::v-deep .is-checked {
          .el-checkbox__input {
            .el-checkbox__inner {
              background-color: #006dcc !important;
              border-color: #006dcc !important;
            }
          }
        }
        ::v-deep .el-progress-bar {
          padding-right: 0;
          width: 50px;
          margin-right: 0;
        }
      }

      .briefDetail_s {
        color: #006dcc;
        cursor: pointer;
      }

      .introduce_content {
        float: left;
        margin: 10px 0 10px 35px;
      }
    }
  }

  .guanbi {
    font-size: 16px;
    position: relative;
    top: 15px;
    left: 93%;
    // cursor: pointer;
    z-index: 1;
  }

  // .left {
  //   margin: 10px 10px 10px 0;
  //   float: left;
  //   position: relative;
  // }

  ::v-deep .right {
    float: right;
    margin-top: 10px;
    width: 33.3%;
    position: relative;
  }

  ::v-deep #activityAndDynamicPublics {
    .optiones {
      .el-tabs__item {
        font-size: 16px;
        font-family: SourceHanSansCN-Bold;
        color: #080707;
        min-width: 70px;
        text-align: center;
        font-weight: bold;
      }

      .is-active {
        font-weight: bold;
      }

      .el-tabs__active-bar {
        background: #006dcc;
      }
    }
  }

  .box-content {
    display: flex;
    padding: 0 34px 0 31px;
    justify-content: space-between;
    height: 64px;
    border-bottom: 1px solid #e1e1e1;
    align-items: center;
    .back-box {
      display: flex;
      align-items: center;
      i {
        cursor: pointer;
      }
    }
    .title {
      color: #080707;
      line-height: 23px;
      font-size: 20px;
      font-weight: bold;
    }
    .four-btn {
      display: flex;
      align-items: center;
      margin-right: 8px;

      .edit-btn {
        width: 32px;
        height: 32px;
        background: #ffffff;
        opacity: 1;
        border: 1px solid #e1e1e1;
        border-right: 0;
        svg {
          padding: 8px;
        }
      }
      .left-radius {
        border-top-left-radius: 4px;
        border-bottom-left-radius: 4px;
      }
      .right-radius {
        border-top-right-radius: 4px;
        border-bottom-right-radius: 4px;
        border-right: 1px solid #e1e1e1;
      }
    }

    .header_right {
      float: right;
      display: flex;
      align-items: center;
      .see-view-btn {
        margin-right: 54px;
        font-size: 12px;
        font-weight: 400;
        color: #000000;
      }
      .dayin {
        width: 28px;
        height: 28px;
        border: 1px solid #ccc;
        background: #fff;
        border-radius: 3px;
        cursor: pointer;
        margin: 16px 0 0 10px;
        svg {
          margin: 5px;
          width: 16px;
          height: 16px;
        }
      }
      ::v-deep .el-button-group {
        display: flex;
        flex-wrap: nowrap;
        position: relative;
        .moreButton {
          margin-bottom: 0;
          li {
            height: 30px;
            line-height: 30px;

            &:hover {
              color: #006dcc;
            }
          }
        }
        ::v-deep .button {
          height: 30px;
          text-align: center;
          padding: 0 15px;
          color: #006dcc;
          border: 1px solid #dedcda;
        }
      }
      .xiala,
      .approval {
        height: 32px;
        background: #ffffff;
        border: 1px solid #dedcda;
        border-radius: 4px 4px 4px 4px;
        opacity: 1;
        border: 1px solid #e1e1e1;
        border: 1px solid #e1e1e1;
        font-size: 12px;
        font-weight: 400;
        color: #080707;
        line-height: 16px;
        padding: 7px 12px;
        cursor: pointer;
        svg {
          width: 16px;
          height: 16px;
        }
      }
      .xiala {
        min-width: 56px;
        margin-left: 8px;
        text-align: center;
      }
      .xiala:hover{
        border:1px solid #2D6cfc;
        color:#2D6cfc;
        background:#d5e2fe;
      }
    }
    .el-tag {
      margin-right: 5px;
      height: 18px;
      padding: 0 4px;
      line-height: 18px;
      border: 0;
    }
    .label {
      margin-left: 40px;
      margin-bottom: 8px;
      margin-right: 40px;
      svg {
        float: left;
        width: 14px;
        display: inline-block;
        margin: 5px 5px 0 0;
      }
    }

    .tag {
      display: flex;
      align-items: center;
      float: left;
      max-width: 100%;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }

    .link {
      min-width: 48px;
      font-size: 12px;

      font-weight: 400;
      color: rgba(0, 109, 204, 1);
      position: relative;
    }
    .detail-header-tag-box {
      display: flex;
      align-items: center;
      padding-top: 5px;
      .detail-header-tag-svg {
        margin: 0 10px 0 0;
      }
    }
  }

  .clears {
    // min-height: 10px;
    // min-height: 68px;
    // margin-top: 64px;
  }
  ::v-deep .el-card.is-always-shadow {
    box-shadow: none;
    border: 1px solid #dddbda;
  }
  ::v-deep .partnercloud {
    .el-dialog__header {
      font-size: 14px;
      color: #080707;
    }
    .el-dialog__body {
      padding: 30px 36px 30px 33px;
    }
  }
  ::v-deep .el-tabs__nav-wrap::after {
    height: 1px !important;
    background-color: #dedcda !important;
  }
  .backtop {
    width: 40px;
    height: 39px;
    position: fixed;
    bottom: 21px;
    right: -5px;
    cursor: pointer;
    // box-shadow: 0 0 5px 0 rgba(0, 0, 0, 0.2);
  }
  .knowledge ::v-deep .el-dialog--center .el-dialog__body {
    min-height: 380px;
    max-height: 380px;
  }
  .QBslowCC {
    z-index: 999999;
    height: 40px;
    background: #fdf6ec;
    width: 24%;
    position: absolute;
    top: 2%;
    left: 38%;
    display: flex;
    justify-content: left;
    align-items: center;
    ::v-deep .el-button.is-loading:before {
      background: none;
    }
  }
  #contentMainBox {
    background: #fff;
    height: 100%;

    .pdfAndrelation {
      height: 100%; //calc(100% - 64px);
      display: flex;
      flex-direction: column;
    }
    .relation-list-box {
      width: 100%;
      height: 100%;
      // max-height: 400px;
    }
    .mainContainer {
      height: 700px;
      // height: calc(100% - 354px);
    }
    .mainContainerBox {
      height: 700px;
      text-align: center;
    }
  }
  .tab-content {
    display: flex;
    flex-direction: column;
    height: 100%;
    .publicAndRelation {
      height: 100%;
      overflow-y: auto;
      .isEnableBox{
        height:200px;
      }
      .pdf-view-box {
        margin: 0 auto;
        margin-top: 20px;
        max-width: 780px;
        background: #ffffff;
        box-shadow: 0px 0px 10px 0px rgba(0,0,0,0.18);
        position: relative;
      }
      .changePage {
        position: absolute;
        font-size: 12px;
        top: 0;
        right: 0;
        width: 120px;
        height: 32px;
        background: #00bc68;
        color: #fff;
        padding: 8px 12px;
        box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.3);
      }
      .streamer-blue,
      .streamer-grey {
        width: 200px;
        height: 140px;
        position: absolute;
        top: -8px;
        left: -8px;
        overflow: hidden;
      }
      .triangle {
        position: absolute;
        left: 113px;
        width: 0;
        height: 0;
        margin: 0 auto;
        border: 7px solid transparent;

        top: -15px;
      }
      .triangle-blue {
        border-bottom-color: #154dcf;
      }
      .triangle-grey {
        border-bottom-color: #888888;
      }
      .triangle1 {
        position: absolute;
        top: 114px;
        width: 0;
        height: 0;
        margin: 0 auto;
        border: 7px solid transparent;

        left: -15px;
      }
      .triangle1-blue {
        border-right-color: #154dcf;
      }
      .triangle1-grey {
        border-right-color: #888888;
      }
      .streamer-blue span,
      .streamer-grey span {
        display: inline-block;
        text-align: center;
        width: 200px;
        height: 30px;
        line-height: 30px;
        position: absolute;
        top: 35px;
        left: -46px;
        z-index: 2;
        overflow: hidden;
        -webkit-transform: rotate(-45deg);
        transform: rotate(-45deg);
        color: #fff;
      }
      .streamer-blue span {
        -webkit-box-shadow: 0 0 0 3px #2d6cfc, 0 14px 7px -9px rgb(0 0 0 / 60%);
        box-shadow: 0 0 0 3px #2d6cfc, 0 14px 7px -9px rgb(0 0 0 / 60%);
        background-color: #2d6cfc;
      }
      .streamer-grey span {
        -webkit-box-shadow: 0 0 0 3px #bbbbbb, 0 14px 7px -9px rgb(0 0 0 / 60%);
        box-shadow: 0 0 0 3px #bbbbbb, 0 14px 7px -9px rgb(0 0 0 / 60%);
        background-color: #bbbbbb;
      }
    }
    .limit-height {
      padding: 20px 23px 20px 23px;
      background: #FCFCFC;
    }
    .relation-content {
      margin: 3px 28px 0px 32px;
      ::v-deep .text-center .el-table--border {
        border-left: 1px solid #dedcda !important;
      }
      ::v-deep .upload-demo {
        border-bottom: 0 !important;
        padding: 0 10px 10px 10px !important;
      }
    }
    .tab-header {
      display: flex;
      width: 100%;
    }
    .el-tabs__nav-scroll {
      height: 44px;
      line-height: 44px;
    }
    ::v-deep .el-tabs {
      width: 100% !important;
      height: 40px;
      line-height: 40px;
    }
    ::v-deep .el-tabs__nav {
      padding: 0 8px;
    }
    ::v-deep .el-tabs--top .el-tabs__item.is-top:nth-child(2) {
      padding-left: 20px !important;
    }
    ::v-deep .el-tabs--top .el-tabs__item.is-top:last-child {
      padding-right: 20px !important;
    }
    ::v-deep .el-tabs__item.is-active {
      background: #f3f7ff !important;
      color: #2d6cfc !important;
      border-top: 2px solid #2d6cfc;
    }
    ::v-deep .el-tabs__active-bar {
      height: 0 !important;
    }
    ::v-deep .el-tabs__nav-wrap::after {
      height: 1px;
      background-color: #e1e1e1;
    }
  }
  </style>
  <style>
  .external {
    padding: 12px 0px 12px 0px !important;
  }
  .pop_small ul {
    margin: 0 -12px;
    min-height: 26px;
  }

  .pop_small li {
    min-height: 26px;
    padding-right: 10px;
    font-size: 12px;
    line-height: 26px;
    cursor: pointer;
    color: #3e3e3c;
  }
  .pop_small li span {
    padding-left: 10px;
  }
  .pop_small li:hover {
    background: #f3f2f2;
    color: #006dcc;
  }
  .type {
    display: flex;
  }
  .typetree {
    width: 80%;
    margin-left: 10%;
  }
  .dialog-type {
    font-size: 16px;
    font-weight: bold;
    word-break: break-word;
  }
  .allTagDialog span {
    margin: 3px;
  }
  </style>
