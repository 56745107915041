var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"pdf-content"},[_c('div',{staticClass:"pdf-header"},[_c('div',{staticClass:"header-title"},[_vm._v(_vm._s(_vm.objectApiName)+" ")]),_c('div',{staticClass:"header-number"},[_vm._v(_vm._s(_vm.objectApiNumber))])]),_c('div',{staticClass:"pdf-information-box"},_vm._l((_vm.templateData),function(item,index){return _c('div',{key:item.id,staticClass:"infor-item"},[_c('div',{directives:[{name:"show",rawName:"v-show",value:(item.hidetitle == 0),expression:"item.hidetitle == 0"}],staticClass:"pdf-infor-header"},[_vm._v(" "+_vm._s(item.name)+" ")]),(item.type != 'list')?_c('div',{staticClass:"pdf-infors",class:index == _vm.templateData.length - 1 ? 'pdf-right' : ''},_vm._l((item.fields),function(filedItem,filedindex){return _c('div',{key:filedItem.id,staticClass:"pdf-infor-item",class:filedindex == Number(item.fields.length) - 1 &&
            index == _vm.templateData.length - 1
              ? 'pdf-total'
              : ''},[(filedItem.name)?_c('div',{staticClass:"pdf-item-label",class:index < _vm.templateData.length - 1 ? 'label-color' : ''},[_vm._v(" "+_vm._s(filedItem.name)+": ")]):_vm._e(),(
              filedItem.name &&
              filedItem.schemetable_id != 'account' &&
              filedItem.schemefieldName != 'account_name' &&
              filedItem.schemefield_type != 'IMG'
            )?_c('div',{staticClass:"pdf-item-value",domProps:{"innerHTML":_vm._s(filedItem.fieldValue)}}):_vm._e(),(
              (filedItem.schemetable_id == 'account' ||
                filedItem.schemefieldName == 'account_name') &&
              filedItem.name &&
              filedItem.schemefield_type != 'IMG'
            )?_c('div',[_c('a',{staticStyle:{"text-decoration":"none","color":"#006dcc","cursor":"pointer"},attrs:{"href":("/#/commonObjects/detail/" + (filedItem.khmc) + "/DETAIL")}},[_vm._v(_vm._s(filedItem.fieldValue))])]):_vm._e(),(filedItem.schemefield_type == 'IMG')?_c('div',{staticClass:"file-img"},[_c('img',{attrs:{"src":_vm.getHeadImg(filedItem.fileid)}})]):_vm._e()])}),0):_vm._e(),(item.type == 'list')?_c('div',{staticClass:"pdf-table"},[_c('el-table',{staticStyle:{"width":"100%","margin-top":"20px"},attrs:{"data":item.templateTable,"border":""}},_vm._l((item.templateHeader),function(headeritem){return _c('el-table-column',{key:headeritem.label,attrs:{"prop":headeritem.prop,"label":headeritem.label,"width":_vm.getComputedWidth(item, headeritem)}})}),1)],1):_vm._e()])}),0)])}
var staticRenderFns = []

export { render, staticRenderFns }